import React, { useMemo } from 'react';

// Define the ad object structure
type Ad = {
  href: string;
  imgSrc: string;
  type: 'long' | 'square';
};

type RandomAdProps = {
  type: 'long' | 'square';
};

const ads: Ad[] = [
  { // 50plus match
    href: "https://ds1.nl/c/?si=474&li=1485690&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/474/1485690/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=474&li=1280836&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/474/1280836/?wi=250877&ws=",
    type: "square",
  },
  { // Alleenstaande mama's
    href: "https://lt45.net/c/?si=11326&li=1760861&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11326/1760861/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://lt45.net/c/?si=11326&li=1760864&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11326/1760864/?wi=250877&ws=",
    type: "square",
  },
  { // Match4me
    href: "https://ds1.nl/c/?si=383&li=1771267&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/383/1771267/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=383&li=1771269&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/383/1771269/?wi=250877&ws=",
    type: "square",
  },
  { // Be2 (NL)
    href: "https://rkn3.net/c/?si=532&li=1111416&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/532/1111416/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://rkn3.net/c/?si=532&li=1111414&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/532/1111414/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://rkn3.net/c/?si=532&li=1111408&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/532/1111408/?wi=250877&ws=",
    type: "square",
  },
  {
    href: "https://rkn3.net/c/?si=532&li=1111409&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/532/1111409/?wi=250877&ws=",
    type: "square",
  },
  { // Be2 (NL) (50+)
    href: "https://rkn3.net/c/?si=532&li=1285842&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/532/1285842/?wi=250877&ws=",
    type: "square",
  },
  {
    href: "https://rkn3.net/c/?si=532&li=1285845&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/532/1285845/?wi=250877&ws=",
    type: "long",
  },
  { // EliteDating (NL)
    href: "https://ds1.nl/c/?si=1818&li=149354&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/1818/149354/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=1818&li=150688&wi=250877&ws=",
    imgSrc: "https://remote.dt71.net/1818/150688/?wi=250877&ws=",
    type: "square",
  },
  { // EliteDating (NL) (50+)
    href: "https://ds1.nl/c/?si=1818&li=1377100&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/1818/1377100/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=1818&li=1377104&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/1818/1377104/?wi=250877&ws=",
    type: "square",
  },
  { // EliteDating (NL) (gay)
    href: "https://ds1.nl/c/?si=1818&li=1377161&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/1818/1377161/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=1818&li=1377169&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/1818/1377169/?wi=250877&ws=",
    type: "square",
  },
  { // C-Date (NL) (gif)
    href: "https://ds1.nl/c/?si=1376&li=1315709&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/1376/1315709/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=1376&li=1315711&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/1376/1315711/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=1376&li=1315713&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/1376/1315713/?wi=250877&ws=",
    type: "square",
  },
  {
    href: "https://ds1.nl/c/?si=1376&li=1315717&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/1376/1315717/?wi=250877&ws=",
    type: "square",
  },
  { // C-Date (NL) (jpg)
    href: "https://ds1.nl/c/?si=1376&li=1623995&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/1376/1623995/?wi=250877&ws=",
    type: "square",
  },
  { // e-Matching (NL)
    href: "https://ds1.nl/c/?si=333&li=1813536&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/333/1813536/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=333&li=1813550&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/333/1813550/?wi=250877&ws=",
    type: "square",
  },
  { // Alleenstaande papa's
    href: "https://lt45.net/c/?si=11330&li=1760903&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11330/1760903/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://lt45.net/c/?si=11330&li=1760906&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11330/1760906/?wi=250877&ws=",
    type: "square",
  },
  { // Opzoeknaarjou (NL)
    href: "https://ds1.nl/c/?si=541&li=1390719&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/541/1390719/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=541&li=1390723&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/541/1390723/?wi=250877&ws=",
    type: "square",
  },
  { // Parship (NL) (couples)
    href: "https://ds1.nl/c/?si=457&li=1705124&wi=250877&ws=",
    imgSrc: "https://remote.dt71.net/457/1705124/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=457&li=1705118&wi=250877&ws=",
    imgSrc: "https://remote.dt71.net/457/1705118/?wi=250877&ws=",
    type: "square",
  },
  { // Parship (NL) (female)
    href: "https://ds1.nl/c/?si=457&li=1705159&wi=250877&ws=",
    imgSrc: "https://remote.dt71.net/457/1705159/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=457&li=1705164&wi=250877&ws=",
    imgSrc: "https://remote.dt71.net/457/1705164/?wi=250877&ws=",
    type: "square",
  },
  { // Parship (NL) (male)
    href: "https://ds1.nl/c/?si=457&li=1705082&wi=250877&ws=",
    imgSrc: "https://remote.dt71.net/457/1705082/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=457&li=1705105&wi=250877&ws=",
    imgSrc: "https://remote.dt71.net/457/1705105/?wi=250877&ws=",
    type: "square",
  },
  { // Parship (NL) (50+)
    href: "https://ds1.nl/c/?si=457&li=1705130&wi=250877&ws=",
    imgSrc: "https://remote.dt71.net/457/1705130/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=457&li=1705135&wi=250877&ws=",
    imgSrc: "https://remote.dt71.net/457/1705135/?wi=250877&ws=",
    type: "square",
  },
  { // MetJou (NL)
    href: "https://lt45.net/c/?si=11342&li=1760847&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11342/1760847/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://lt45.net/c/?si=11342&li=1760850&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11342/1760850/?wi=250877&ws=",
    type: "square",
  },
  { // MaatjeMeer-Match (NL)
    href: "https://lt45.net/c/?si=11338&li=1760938&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11338/1760938/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://lt45.net/c/?si=11338&li=1760941&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11338/1760941/?wi=250877&ws=",
    type: "square",
  },
  { // StedenDating (NL)
    href: "https://lt45.net/c/?si=11350&li=1760966&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11350/1760966/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://lt45.net/c/?si=11350&li=1760969&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11350/1760969/?wi=250877&ws=",
    type: "square",
  },
  { // Second Love (NL)
    href: "https://jdt8.net/c/?si=1135&li=1795348&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/1135/1795348/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://jdt8.net/c/?si=1135&li=1795350&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/1135/1795350/?wi=250877&ws=",
    type: "square",
  },
  { // Bilove (NL)
    href: "https://jdt8.net/c/?si=8505&li=1746802&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/8505/1746802/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://jdt8.net/c/?si=8505&li=1746809&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/8505/1746809/?wi=250877&ws=",
    type: "square",
  },
  { // eMatch (NL) 
    href: "https://ds1.nl/c/?si=13014&li=1577287&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/13014/1577287/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=13014&li=1577290&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/13014/1577290/?wi=250877&ws=",
    type: "square",
  },
  { // Seniorengeluk (NL)
    href: "https://lt45.net/c/?si=11320&li=1760952&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11320/1760952/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://lt45.net/c/?si=11320&li=1760955&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11320/1760955/?wi=250877&ws=",
    type: "square",
  },
  { // Mediumastro (NL)
    href: "https://lt45.net/c/?si=14219&li=1612735&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/14219/1612735/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://lt45.net/c/?si=14219&li=1612739&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/14219/1612739/?wi=250877&ws=",
    type: "square",
  },
  { // Funenfeestwinkel.nl
    href: "https://animated.dt71.net/14219/1612739/?wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/2006/119022/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=2006&li=1217861&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/2006/1217861/?wi=250877&ws=",
    type: "square",
  },
  { // Weetwiejedate (NL)
    href: "https://ds1.nl/c/?si=1708&li=102455&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/1708/102455/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=1708&li=102453&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/1708/102453/?wi=250877&ws=",
    type: "square",
  },
  { // Guys.G-Dating (NL)
    href: "https://lt45.net/c/?si=11384&li=1760924&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11384/1760924/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://lt45.net/c/?si=11384&li=1760927&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11384/1760927/?wi=250877&ws=",
    type: "square",
  },
  { // VijftigPlusDating (NL)
    href: "https://ds1.nl/c/?si=2770&li=1850883&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/2770/1850883/?wi=250877&ws=",
    type: "square",
  },
  {
    href: "https://ds1.nl/c/?si=2770&li=1850886&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/2770/1850886/?wi=250877&ws=",
    type: "long",
  },
  { // Gaybuddy.nl
    href: "https://lt45.net/c/?si=10546&li=1469984&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/10546/1469984/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://lt45.net/c/?si=10546&li=1469992&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/10546/1469992/?wi=250877&ws=",
    type: "square",
  },
  { // Erovibes 
    href: "https://lt45.net/c/?si=11454&li=1509542&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11454/1509542/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://lt45.net/c/?si=11454&li=1509548&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/11454/1509548/?wi=250877&ws=",
    type: "square",
  },
  { // Alleenstaande-Vaders (NL)
    href: "https://ds1.nl/c/?si=6504&li=1303560&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/6504/1303560/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=6504&li=1303576&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/6504/1303576/?wi=250877&ws=",
    type: "square",
  },
  { // Lexa (NL)
    href: "https://ds1.nl/c/?si=51&li=1767223&wi=250877&ws=",
    imgSrc: "https://animated.dt71.net/51/1767223/?wi=250877&ws=",
    type: "long",
  },
  {
    href: "https://ds1.nl/c/?si=51&li=1767219&wi=250877&ws=",
    imgSrc: "https://static-dscn.net/51/1767219/?wi=250877&ws=",
    type: "square",
  }
]; 

const getRandomAd = (type: 'long' | 'square') => {
  const filteredAds = ads.filter(ad => ad.type === type);
  const randomIndex = Math.floor(Math.random() * filteredAds.length);
  return filteredAds[randomIndex];
};

const RandomAd: React.FC<RandomAdProps> = ({ type }) => {
  const ad = useMemo(() => getRandomAd(type), [type]);

  if (!ad) return null;

  return (
    <a href={ad.href} rel="sponsored" target="_blank">
      <img
        src={ad.imgSrc}
        alt=""
        style={{
          maxWidth: '100%',
          height: 'auto',
          border: 'none',
          overflow: 'hidden',
        }}
      />
    </a>
  );
};

export default RandomAd;
