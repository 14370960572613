import { CometChat } from '@cometchat-pro/chat';
import Modal from 'anchor-ui/modal';
import Button from 'anchor-ui/button';
import Input from 'anchor-ui/input';
import { useEffect, useState } from 'react';

const EditMessageModal = ({
  editMessage,
  setEditMessage,
  setMessages,
  setAlert,
}: {
  editMessage: CometChat.TextMessage | null;
  setEditMessage: (editMessage: CometChat.TextMessage | null) => void;
  setMessages: React.Dispatch<React.SetStateAction<CometChat.BaseMessage[]>>;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
}) => {

  const [editText, setEditText] = useState(editMessage?.getText() || '');

  useEffect(() => {
    if (editMessage) {
      setEditText(editMessage.getText());
    }
  }, [editMessage]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditText(e.target.value);
  }

  const handleClose = () => {
    setEditText('');
    setEditMessage(null);
  };

  const handleSaveChanges = () => {
    if (!editMessage) return;
    if (editText === editMessage.getText()) {
      handleClose();
      return;
    }

    editMessage.setText(editText);
    CometChat.editMessage(editMessage).then(
      message => {
        const updatedMessage = message as CometChat.TextMessage;
        setMessages((prevMessages) => {
          const updatedMessages = prevMessages.map((msg) => {
            if (msg.getId() === updatedMessage.getId()) {
              return updatedMessage;
            }
            return msg;
          });
          return updatedMessages;
        });
        handleClose();
      }, error => {
        console.error('Message update failed with error:', error);
        setAlert({ message: 'Bericht wijzigen mislukt.', type: 'error' });
      }
    );
  }

  if (!editMessage) return <></>;

  return (
    <Modal
      open={editMessage !== null}
      actions={
        <>
          <Button flatButton onClick={handleClose}>ANNULEER</Button>
          <Button flatButton onClick={handleSaveChanges}>WIJZIG</Button>
        </>
      }
      headerStyle={{ fontSize: '16px' }}
    >
      <Input
        name={'extraInfo'}
        label={'Bericht'}
        value={editText}
        onChange={handleChange}
        maxLength={130}
        style={{ maxWidth: '100%' }}
      />
    </Modal>
  );
};

export default EditMessageModal;