import { CometChat } from '@cometchat-pro/chat';
import Modal from 'anchor-ui/modal';
import Button from 'anchor-ui/button';

const RemoveFriendModal = ({
  friendToDelete,
  setFriendToDelete,
  handleRemoveFriend,
}: {
  friendToDelete: CometChat.User | null,
  setFriendToDelete: (friend: CometChat.User | null) => void,
  handleRemoveFriend: (friend: CometChat.User) => void,
}) => {

  const handleClose = () => {
    setFriendToDelete(null);
  }

  const removeFriend = () => {
    if (friendToDelete) {
      handleRemoveFriend(friendToDelete);
      setFriendToDelete(null);
    }
  }

  if (!friendToDelete) return <></>;

  return (
    <Modal
      open={friendToDelete !== null}
      header={
        <div className="addGroupModalHeaderContainer">
          <div className="step-one">
            <span className="addGroupModalHeaderTitle">
              Weet je zeker dat je je vriendschap met {friendToDelete.getName()} wil beëindigen? Dit is permanent en kan niet worden teruggedraaid.
            </span>
          </div>
        </div>
      }
      actions={
        <>
          <Button flatButton onClick={handleClose}>ANNULEER</Button>
          <Button flatButton onClick={removeFriend}>VERWIJDER VRIEND</Button>
        </>
      }
      headerStyle={{ fontSize: '16px', }}
    >
    </Modal>
  );
}

export default RemoveFriendModal;