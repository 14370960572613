import MessageList from 'anchor-ui/message-list';

import { ReactNode } from 'react';

const MessageListComponent = ({ children }: { children: ReactNode }) => {
  return (
    <MessageList autoScroll>
      {children}
    </MessageList>
  );
}

export default MessageListComponent;