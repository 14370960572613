import { CometChat } from "@cometchat-pro/chat";
import Input from 'anchor-ui/input';
import Button from 'anchor-ui/button';
import { IconEdit } from 'anchor-ui/icons';
import Divider from 'anchor-ui/divider';
import { SetStateAction, useState } from "react";
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import { validatePassword } from '../../../utils/validatePassword';
import DeleteAccountModal from "./DeleteAccountModal";
import { Functions } from "firebase/functions";

const AccountPage = ({
  loggedInUser,
  setAlert,
  functions,
  handleLogout,
}: {
  loggedInUser: CometChat.User,
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>,
  functions: Functions,
  handleLogout: () => void,
}) => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<{ input: string, msg: string } | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const cancelChangePassword = () => {
    setShowChangePassword(false);
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setError(null);
  }

  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    const auth = getAuth();
    const user = auth.currentUser;

    if (newPassword !== confirmPassword) {
      setError({ input: 'confirm-pw', msg: 'Wachtwoorden komen niet overeen.' });
      return;
    }

    // Validate password and show specific error if invalid
    const passwordValidationError = await validatePassword(auth, newPassword);
    if (passwordValidationError) {
      setError({ input: 'new-pw', msg: passwordValidationError });
      return;
    }

    if (user) {
      const credential = EmailAuthProvider.credential(user.email!, oldPassword);

      try {
        setIsSubmitting(true);
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, newPassword);
        setShowChangePassword(false);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setAlert({ message: 'Je wachtwoord is gewijzigd', type: 'success' });
      } catch (error) {
        if ((error as any).code === 'auth/invalid-credential') {
          setError({ input: 'old-pw', msg: 'Verkeerd wachtwoord' });
        } else {
          setError({ input: 'general', msg: 'Er is iets misgegaan. Probeer het later opnieuw.' });
          setAlert({ message: 'Er is iets misgegaan. Probeer het later opnieuw.', type: 'error' });
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <div>

      <DeleteAccountModal
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        setAlert={setAlert}
        functions={functions}
        handleLogout={handleLogout}
      />

      <h2>Account Page</h2>

      <section className="account-section">
        <span className="account-label">
          <span className="account-label-text">
            Gebruikersnaam
          </span>
        </span>
        <p className="account-username">{loggedInUser.getName()}</p>
      </section>

      <section className="account-section">
        {showChangePassword ? (
          <form onSubmit={handleChangePassword}>
            <Input
              label="Oud Wachtwoord"
              required
              maxLength={256}
              value={oldPassword}
              onChange={(e: { target: { value: SetStateAction<string>; }; }) => setOldPassword(e.target.value)}
              error={error?.input === 'old-pw' ? error.msg : undefined}
              type="password"
            />
            <Input
              label="Nieuw Wachtwoord"
              required
              maxLength={256}
              value={newPassword}
              onChange={(e: { target: { value: SetStateAction<string>; }; }) => setNewPassword(e.target.value)}
              error={error?.input === 'new-pw' ? error.msg : undefined}
              type="password"
            />
            <Input
              label="Bevestig Wachtwoord"
              required
              maxLength={256}
              value={confirmPassword}
              onChange={(e: { target: { value: SetStateAction<string>; }; }) => setConfirmPassword(e.target.value)}
              error={error?.input === 'confirm-pw' ? error.msg : undefined}
              type="password"
            />

            <div>
              <Button type="submit" disabled={isSubmitting || !oldPassword || !newPassword || !confirmPassword}>
                Wijzig
              </Button>
              <Button
                inverted
                onClick={cancelChangePassword}
              >
                Annuleer
              </Button>
            </div>
            {error?.input === 'general' && <p className="error-message">{error.msg}</p>}
          </form>
        ) :
          <div className="change-password" onClick={() => setShowChangePassword(true)}>
            <Input
              label="Wachtwoord"
              value="*********"
            />
            <Button iconButton><IconEdit /></Button>
          </div>
        }
      </section>

      <section className="account-section delete-account">
        <Divider />
        <Button onClick={() => setModalOpen(true)}>Verwijder Account</Button>
      </section>
    </div>
  );
}

export default AccountPage;