import { CometChat } from '@cometchat-pro/chat';

import ChannelHeader from 'anchor-ui/channel-header';
import Card from 'anchor-ui/card';
import Profile from 'anchor-ui/profile';
import Button from 'anchor-ui/button';
import CardHeader from 'anchor-ui/card-header';
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import Avatar from 'anchor-ui/avatar';

import defaultAvatar from "./../../assets/default_avatar.jpg";
import { getActiveColor } from './../../utils/activeColor';
import { getGenderFromMetadata } from './../../utils/getGenderFromMetadata';
import { GroupConversation } from './../../utils/class/GroupConversation';

import FriendButtons from './../../components/LeftSideBarMenu/FriendsListComponent/FriendButtons';
import ConversationsListComponent from './../../components/LeftSideBarMenu/ConversationListComponent';
import { ActiveChannelInterface } from './../../interfaces/activeChannelInterface';
import ToggleLeftMenuButton from '../ToggleLeftMenuButton';

import './styles.css';
import { checkIfUserIsBlocked } from '../../utils/checkIfUserIsBlocked';
import RandomAd from './RandomAd';
import { Functions } from 'firebase/functions';

const Timeline = ({
  // User-related props
  loggedInUser,
  functions,

  // Friend-related props
  friends,
  showUserProfile,
  startPrivateChat,
  setUserToReport,
  setFriendToDelete,
  blockedUsers,
  handleBlockUser,

  // Conversation-related props
  conversations,
  unreadCount,
  handleConversationClick,
  handleGroupConversationClick,
  initialConversation,
  currentChat,
  setCurrentChat,
  refreshConversations,
  lastConversationMsg,
  setUnreadCount,

  // Alert-related props
  setAlert,

  // Menu-related props
  leftMenuOpen,
  setLeftMenuOpen,
}: {
  // User-related props
  loggedInUser: CometChat.User;
  functions: Functions;

  // Friend-related props
  friends: CometChat.User[];
  showUserProfile: (user: CometChat.User) => void;
  startPrivateChat: (user: CometChat.User) => void;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  setFriendToDelete: (friend: CometChat.User | null) => void;
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;

  // Conversation-related props
  conversations: CometChat.Conversation[] | GroupConversation[];
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  handleConversationClick: (conversation: CometChat.Conversation) => void;
  handleGroupConversationClick: (group: CometChat.Group) => Promise<void>;
  initialConversation: CometChat.Conversation | null;
  currentChat: ActiveChannelInterface;
  setCurrentChat: React.Dispatch<React.SetStateAction<ActiveChannelInterface>>;
  refreshConversations: () => void;
  lastConversationMsg: { conversationId: string, receiverId: string, message: string };
  setUnreadCount: React.Dispatch<React.SetStateAction<{
    [id: string]: { nr: number; chat: ActiveChannelInterface; };
  }>>;

  // Alert-related props
  setAlert: (alert: { message: string, type: string }) => void;

  // Menu-related props
  leftMenuOpen: boolean;
  setLeftMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {

  const onlineFriends = friends.filter(friend => friend.getStatus() === 'online');
  return (
    <div className='timeline-container'>

      <ChannelHeader
        name="Tijdlijn"
        style={{ lineHeight: '18px' }}
        leftButton={
          <ToggleLeftMenuButton
            leftMenuOpen={leftMenuOpen}
            setLeftMenuOpen={setLeftMenuOpen}
            unreadCount={unreadCount}
          />
        }
      />

      <div className='timeline-top'>
        <Card style={{ height: '100%', width: '100%', backgroundColor: 'white' }}>
          <RandomAd type='long' />
        </Card>
      </div>

      <section className='timeline-section'>
        <div className='timeline-card timeline-card-left'>
          <Card>
            <Profile
              header={loggedInUser.getName()}
              button={
                <Button onClick={() => setCurrentChat((prev: any) => ({ ...prev, userProfile: loggedInUser }))}>
                  Bekijk Profiel
                </Button>
              }
              avatar={loggedInUser.getAvatar() ?? defaultAvatar}
              coverImage={loggedInUser.getAvatar() ?? defaultAvatar}
              coverImageStyle={{ backgroundColor: getActiveColor }}
              style={{ paddingBottom: '16px' }}
            />
          </Card>

          <div className='timeline-conversations'>
            <Card style={{ height: '100%', backgroundColor: 'white', marginTop: '16px' }}>
              <ConversationsListComponent
                conversations={conversations}
                unreadCount={unreadCount}
                handleConversationClick={handleConversationClick}
                handleGroupConversationClick={handleGroupConversationClick}
                initialConversation={initialConversation}
                currentChat={currentChat}
                setCurrentChat={setCurrentChat}
                friends={friends}
                refreshConversations={refreshConversations}
                lastConversationMsg={lastConversationMsg}
                setUnreadCount={setUnreadCount}
                loggedInUser={loggedInUser}
                blockedUsers={blockedUsers}
                setAlert={setAlert}
                isTimeline
                functions={functions}
              />
            </Card>
          </div>
        </div>

        <div className='timeline-card timeline-middle-card'>
          <Card style={{ height: '100%', backgroundColor: 'white' }}>
            <RandomAd type='square' />
          </Card>
        </div>

        <div className='timeline-card timeline-friends'>
          <Card style={{ height: '100%', backgroundColor: 'white' }}>
            <CardHeader title={`Online vrienden (${onlineFriends.length})`} />
            <List>
              {onlineFriends.map((friend, index) => (
                <ListItem
                  key={index}
                  primaryText={friend.getName()}
                  secondaryText={getGenderFromMetadata(friend)}
                  avatar={
                    <Avatar
                      image={friend.getAvatar() ?? defaultAvatar}
                      style={{ backgroundColor: getActiveColor() }}
                      status={friend.getStatus()}
                    />
                  }
                  blocked={checkIfUserIsBlocked(blockedUsers, friend.getUid())}
                  rightButton={
                    <FriendButtons
                      friend={friend}
                      showUserProfile={showUserProfile}
                      startPrivateChat={startPrivateChat}
                      setUserToReport={setUserToReport}
                      setFriendToDelete={setFriendToDelete}
                      blockedUsers={blockedUsers}
                      handleBlockUser={handleBlockUser}
                    />
                  }
                />
              ))}
            </List>
          </Card>

          <div className='timeline-card timeline-bottom-right'>
            <Card style={{ height: '100%', backgroundColor: 'white' }}>
              <RandomAd type='square' />
            </Card>
          </div>
        </div>

      </section>
    </div>
  );
}

export default Timeline;