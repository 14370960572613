import { useRef, useState, useEffect } from 'react';
import { CometChat } from '@cometchat-pro/chat';
import Button from 'anchor-ui/button';
import MessageInput from 'anchor-ui/message-input';
import EmojiMenu from 'anchor-ui/emoji-menu';
import Commands from 'anchor-ui/commands';
import { IconEmoji, IconAddImage, IconSticker } from 'anchor-ui/icons';
import defaultAvatar from "./../../assets/default_avatar.jpg";
import CreatePollModal from './CreatePollModal';
import { ActiveChannelInterface } from '../../interfaces/activeChannelInterface';
import GifPicker from 'gif-picker-react';

const MessageInputComponent = ({
  handleChange,
  value,
  handleSendMessage,
  handleSendImage,
  groupMembers,
  chatWithUser,
  emojiMenuOpen,
  setEmojiMenuOpen,
  setCommandMenuOpen,
  currentChat,
}: {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  handleSendMessage: (event: any, messageText?: string) => void;
  handleSendImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  groupMembers?: CometChat.GroupMember[];
  chatWithUser?: CometChat.User;
  emojiMenuOpen: boolean;
  setEmojiMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCommandMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentChat: ActiveChannelInterface;
}) => {

  const [pollModalOpen, setPollModalOpen] = useState(false);
  const [displayGifPicker, setDisplayGifPicker] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const gifPickerRef = useRef<HTMLDivElement>(null);

  const mentionableUsers = groupMembers?.map((member) => ({
    value: member.getName(),
    prefix: '@',
    avatar: member.getAvatar() || defaultAvatar,
  }));

  if (chatWithUser) {
    mentionableUsers?.push({
      value: chatWithUser.getName(),
      prefix: '@',
      avatar: chatWithUser.getAvatar() || defaultAvatar,
    });
  };

  const commands = [
    ...mentionableUsers || [],
    {
      value: 'poll',
      prefix: '/',
    },
    {
      value: 'gif',
      prefix: '/',
    }
  ];

  const handleImageClick = () => {
    if (!fileInputRef.current) return;
    fileInputRef.current.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSendImage(event);
  };

  const extractEmojis = (text: string) => {
    const emojiRegex = /\p{Emoji_Presentation}|\p{Extended_Pictographic}/gu;
    return text.match(emojiRegex);
  };

  const sendEmoji = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const emoji = extractEmojis(event.currentTarget.innerHTML);

    if (!emoji) {
      console.error('Invalid emoji object');
      return;
    }

    handleChange({ target: { value: value + emoji[0] } } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleMention = (command: string) => {

    const cursorPosition = value.indexOf('@');
    const newValue = value.slice(0, cursorPosition) + command + ' ' + value.slice(cursorPosition + 1);

    // Set the new value
    handleChange({ target: { value: newValue } } as React.ChangeEvent<HTMLInputElement>);
  };

  const handleCommand = (event: object, command: string) => {
    if (command.includes('@')) {
      handleMention(command);
    } else if (command === '/poll') {
      handleChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
      setPollModalOpen(true);
    } else if (command === '/gif') {
      handleChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
      setDisplayGifPicker(true);
    }
  };

  const handleSendGif = (gif: {url: string}) => {
    const event = { key: ''};
    handleSendMessage(event, gif.url);
    setDisplayGifPicker(false);
  }

  // Close GifPicker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (gifPickerRef.current && !gifPickerRef.current.contains(event.target as Node)) {
        setDisplayGifPicker(false);
      }
    };

    if (displayGifPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [displayGifPicker]);

  return (
    <div className="message-input">
      <CreatePollModal
        open={pollModalOpen}
        setOpen={setPollModalOpen}
        currentChat={currentChat}
      />

      <EmojiMenu
        sendEmoji={sendEmoji}
        open={emojiMenuOpen}
        hideMenu={() => setEmojiMenuOpen(false)}
        style={{
          width: '100%',
          marginBottom: '5px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />

      {displayGifPicker && (
        <div ref={gifPickerRef}>
          <GifPicker
            tenorApiKey={process.env.REACT_APP_TENOR_API_KEY + ''}
            country='NL'
            locale='nl_NL'
            onGifClick={(e) => handleSendGif(e)}
          />
        </div>
      )}

      <Commands
        header={value.includes('@') ? 'Mention' : 'Commands'}
        value={value}
        commands={commands}
        onSelect={handleCommand}
        onMenuOpen={() => setCommandMenuOpen(true)}
        onMenuClose={() => setCommandMenuOpen(false)}

        onChange={() => { }}

        style={{
          width: '100%',
          zIndex: 1000,
          marginBottom: '5px',
          position: 'relative',
        }}
      />

      <MessageInput
        onChange={handleChange}
        placeholder="Typ iets..."
        value={value}
        sendMessage={handleSendMessage}
        multiLine
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
        leftButton={
          <Button iconButton onClick={() => setEmojiMenuOpen(!emojiMenuOpen)}>
            <IconEmoji />
          </Button>
        }
        rightButton={
          <>
            <Button iconButton onClick={() => setDisplayGifPicker(!displayGifPicker)}>
              <IconSticker />
            </Button>
            <Button iconButton onClick={handleImageClick}>
              <IconAddImage />
            </Button>
          </>
        }
      />
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept="image/*"
      />
    </div>
  );
};

export default MessageInputComponent;