import React from 'react';

interface ProfileDetailProps {
  icon?: React.ReactNode;
  label: string;
  value: string | undefined;
}

const ProfileDetail: React.FC<ProfileDetailProps> = ({ icon, label, value }) => {
  if (!value) return null;

  return (
    <div className="profile-detail">
      
      <span className="profile-detail-key">{label}</span>
      <span className="profile-detail-value">{value}</span>
    </div>
  );
};

export default ProfileDetail;