import { Auth, validatePassword as firebaseValidatePassword } from 'firebase/auth';

export const validatePassword = async (auth: Auth, password: string): Promise<string> => {
  const passwordValidationStatus = await firebaseValidatePassword(auth, password);
  const errorMessages = [];

  if (!passwordValidationStatus.isValid) {
    if (passwordValidationStatus.meetsMinPasswordLength === false) {
      errorMessages.push('Wachtwoord moet minstens de minimale lengte hebben.');
    }
    if (passwordValidationStatus.meetsMaxPasswordLength === false) {
      errorMessages.push('Wachtwoord mag niet langer zijn dan de maximale lengte.');
    }
    if (passwordValidationStatus.containsLowercaseLetter === false) {
      errorMessages.push('Wachtwoord moet minstens één kleine letter bevatten.');
    }
    if (passwordValidationStatus.containsUppercaseLetter === false) {
      errorMessages.push('Wachtwoord moet minstens één hoofdletter bevatten.');
    }
    if (passwordValidationStatus.containsNumericCharacter === false) {
      errorMessages.push('Wachtwoord moet minstens één cijfer bevatten.');
    }
    if (passwordValidationStatus.containsNonAlphanumericCharacter === false) {
      errorMessages.push('Wachtwoord moet minstens één speciaal teken bevatten.');
    }
  }

  return errorMessages.join('\n');
};