import ChannelHeader from 'anchor-ui/channel-header';
import Button from 'anchor-ui/button';
import { IconHome, IconSettings } from 'anchor-ui/icons';
import { getProfileFromMetadata, updateUserMetadata } from '../../utils/updateMetadata';
import { metadataInterface } from './../../interfaces/metadataInterface';
import { CometChat } from '@cometchat-pro/chat';
import { setActiveColor } from '../../utils/activeColor';
import './styles.css';
import ThemePage from './ThemePage';
import PrivacyPage from './PrivacyPage';
import AccountPage from './AccountPage';
import BlockedUsersPage from './BlockedUsersPage';
import { useState } from 'react';
import LeftSettingsMenu from './LeftSettingsMenu';
import Menu from 'anchor-ui/menu';
import { Functions } from 'firebase/functions';
import VisualsPage from './VisualsPage';

const SettingsPage = ({
  setCurrentChat,
  loggedInUser,
  setAlert,
  activeColorState,
  setActiveColorState,

  messageFontSize,
  setMessageFontSize,
  generalFontSize,
  setGeneralFontSize,

  blockedUsers,
  handleBlockUser,
  functions,
  handleLogout,
}: {
  setCurrentChat: (chat: any) => void;
  loggedInUser: CometChat.User;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  activeColorState: string;
  setActiveColorState: (color: string) => void;

  messageFontSize: string;
  setMessageFontSize: (fontSize: string) => void;
  generalFontSize: string;
  setGeneralFontSize: (fontSize: string) => void;

  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  functions: Functions;
  handleLogout: () => void;
}) => {
  const [userProfile, setUserProfile] = useState(getProfileFromMetadata(loggedInUser));
  const [activePage, setActivePage] = useState('account');

  const [leftMenuOpen, setLeftMenuOpen] = useState(false);
  const [isPublic, setIsPublic] = useState(userProfile.settings?.profileIsPrivate ?? false);


  const handleNavigateHome = () => {
    setCurrentChat({ id: '', icon: '', name: '', isGroup: false });
  };

  const handleUpdateUserMetadata = async (newMetadata: metadataInterface) => {
    const updatedMetadata = await updateUserMetadata(newMetadata, loggedInUser);

    if (updatedMetadata && typeof updatedMetadata === 'object') {
      setUserProfile(updatedMetadata);
      return true;
    } else {
      setAlert({ message: 'Er is iets misgegaan bij het updaten van je profiel', type: 'error' });
      return false;
    }
  };

  const changeVisiblity = async () => {
    const success = await updateUserMetadata({ ...userProfile, settings: { ...userProfile.settings, profileIsPrivate: !isPublic } }, loggedInUser);

    if (success) {
      setIsPublic(!isPublic);
      setAlert({ message: 'Je privacy instellingen zijn aangepast', type: 'success' });
      return;
    }

    setAlert({ message: 'Er is iets misgegaan bij het updaten van je privacy instellingen', type: 'error' });
  }


  const handleSetActiveColor = async (color: string) => {
    await handleUpdateUserMetadata({ ...userProfile, settings: { ...userProfile.settings, themeColor: color } });
    setActiveColor(color);
    setActiveColorState(color);
  };

  const handleSetMessageFontSize = async (fontSize: string) => {
    await handleUpdateUserMetadata({ ...userProfile, settings: { ...userProfile.settings, messageFontSize: fontSize } });
    setMessageFontSize(fontSize);
  };

  const handleSetGeneralFontSize = async (fontSize: string) => {
    await handleUpdateUserMetadata({ ...userProfile, settings: { ...userProfile.settings, generalFontSize: fontSize } });
    setGeneralFontSize(fontSize);
  };

  return (
    <section className="white-page-popover settings-page">

      <Menu
        closeMenu={() => setLeftMenuOpen(!leftMenuOpen)}
        open={leftMenuOpen}
        style={{ backgroundColor: 'transparent' }}
      >
        <LeftSettingsMenu
          activeColorState={activeColorState}
          activePage={activePage}
          setActivePage={setActivePage}
          isSideNav
          handleClose={() => setLeftMenuOpen(false)}
        />
      </Menu>

      <LeftSettingsMenu
        activeColorState={activeColorState}
        activePage={activePage}
        setActivePage={setActivePage}
      />

      <div className='settings-body'>
        <ChannelHeader
          name="Instellingen"
          leftButton={
            <Button
              iconButton
              className='button-toggle-menu'
              onClick={() => setLeftMenuOpen(true)}
            >
              <IconSettings
                color={activeColorState}
              />
            </Button>
          }
          rightButton={
            <Button iconButton onClick={handleNavigateHome}>
              <IconHome />
            </Button>
          }
        />

        <section className='settings-content'>
          {activePage === 'account' ? (
            <AccountPage
              loggedInUser={loggedInUser}
              setAlert={setAlert}
              functions={functions}
              handleLogout={handleLogout}
            />
          ) :
            activePage === 'privacy' ? (
              <PrivacyPage
                isPublic={isPublic}
                changeVisiblity={changeVisiblity}
              />
            ) :
              activePage === 'themes' ? (
                <ThemePage
                  activeColorState={activeColorState}
                  handleSetActiveColor={handleSetActiveColor}
                />
              ) :
                activePage === 'visuals' ? (
                  <VisualsPage
                    messageFontSize={messageFontSize}
                    handleSetMessageFontSize={handleSetMessageFontSize}
                    generalFontSize={generalFontSize}
                    handleSetGeneralFontSize={handleSetGeneralFontSize}
                  />
                ) : (
                  <BlockedUsersPage
                    blockedUsers={blockedUsers}
                    handleBlockUser={handleBlockUser}
                  />
                )
          }
        </section>

      </div>
    </section>
  );
};

export default SettingsPage;