import { CometChat } from '@cometchat-pro/chat';
import Button from 'anchor-ui/button';
import Input from 'anchor-ui/input';
import { SetStateAction, useEffect, useState } from 'react';
import RadioButton from 'anchor-ui/radio-button';
import { metadataInterface } from '../../interfaces/metadataInterface';
import { getProfileFromMetadata, updateUserMetadata } from '../../utils/updateMetadata';
import { useNavigate } from 'react-router-dom';
import Card from 'anchor-ui/card';
import CardHeader from 'anchor-ui/card-header';
import './styles.css';
import ChatPlazaLogo from '../../assets/chatplaza-logo.svg';

const MetadataPage = ({
  setAlert,
  loggedInUser,
  handleLogout,
}: {
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  loggedInUser: CometChat.User;
  handleLogout: () => void;
}) => {
  const [gender, setGender] = useState<string>('Man');
  const [birthDate, setBirthDate] = useState<string>('2000-01-01');
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedInUser || typeof loggedInUser.getName !== 'function') {
      navigate('/login');
      return;
    }

    const metadata = getProfileFromMetadata(loggedInUser);
    if (metadata.about.birthDate !== 'unknown' || typeof metadata === 'boolean') {
      navigate('/chat');
    }
  }, [loggedInUser, navigate]);

  const handleSetMetadata = async () => {
    if (!loggedInUser) return;

    try {
      const metadata: metadataInterface = {
        about: {
          gender,
          birthDate,
        },
      };

      const updatedMetadata = await updateUserMetadata(metadata, loggedInUser);
      if (!updatedMetadata || typeof updatedMetadata === 'boolean') return;

      setAlert({ message: 'Je profiel is aangemaakt!', type: 'success' });
      if (updatedMetadata.about.birthDate !== 'unknown') navigate('/chat');
    } catch (error) {
      console.error('Error setting metadata:', error);
      setAlert({ message: 'Er is iets misgegaan bij het aanmaken van je profiel. Probeer het later opnieuw.', type: 'error' });
    }
  };

  return (
    <section className="action-page">
      <Card className="action-card">
        <img src={ChatPlazaLogo} className='logo' />
        <CardHeader title="Vul uw gegevens in" style={{ alignSelf: "center" }} />
        <CardHeader title="Gender" />
        <div className="action-radio-group">
          <RadioButton
            label="Man"
            value="Man"
            checked={gender === 'Man'}
            onChange={(e: { target: { value: SetStateAction<string> } }) => setGender(e.target.value)}
          />
          <RadioButton
            label="Vrouw"
            value="Vrouw"
            checked={gender === 'Vrouw'}
            onChange={(e: { target: { value: SetStateAction<string> } }) => setGender(e.target.value)}
          />
          <RadioButton
            label="Anders"
            value="Anders"
            checked={gender === 'Anders'}
            onChange={(e: { target: { value: SetStateAction<string> } }) => setGender(e.target.value)}
          />
        </div>
        <Input
          label="Geboortedatum"
          type="date"
          value={birthDate}
          name="birthDate"
          onChange={(e: { target: { value: SetStateAction<string> } }) => setBirthDate(e.target.value)}
          required
          max={new Date().toISOString().split('T')[0]}
          maxLength={50}
        />
        <div className="action-buttons">
          <Button inverted onClick={handleLogout}>Log Uit</Button>
          <Button onClick={handleSetMetadata}>
            Bewaar
          </Button>
        </div>
      </Card>
    </section>
  );

};

export default MetadataPage;
