import { CometChat } from '@cometchat-pro/chat';
import Profile from 'anchor-ui/profile';
import defaultAvatar from "./../../../assets/default_avatar.jpg";
import Button from 'anchor-ui/button';
import './styles.css';
import { IconClose, IconEdit } from 'anchor-ui/icons';
import './../userProfile.css';
import { metadataInterface } from './../../../interfaces/metadataInterface';
import AccountDetailsSection from './AccountDetailsSection';
import { useState } from 'react';
import { getActiveColor } from './../../../utils/activeColor';
import ToggleLeftMenuButton from '../../ToggleLeftMenuButton';
import { ActiveChannelInterface } from '../../../interfaces/activeChannelInterface';
import EditAvatarPopover from './EditAvatarPopover';
import { getProfileFromMetadata, updateUserMetadata } from '../../../utils/updateMetadata';

const LoggedInUserProfile = ({
  user,
  setCurrentChat,

  leftMenuOpen,
  setLeftMenuOpen,
  unreadCount,

  setAlert,
}: {
  user: CometChat.User;
  setCurrentChat: (chat: any) => void;

  leftMenuOpen: boolean;
  setLeftMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>
}) => {
  const [editAvatarOpen, setEditAvatarOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(getProfileFromMetadata(user));

  const handleUpdateUserMetadata = async (newMetadata: metadataInterface) => {
    const updatedMetadata = await updateUserMetadata(newMetadata, user);

    // if (updatedMetadata) { also check if its a metadatainterface
    if (updatedMetadata && typeof updatedMetadata === 'object') {
      setUserProfile(updatedMetadata);
      return true;
    } else {
      setAlert({ message: 'Er is iets misgegaan bij het updaten van je profiel', type: 'error' });
      return false;
    }
  }

  const generalItems = [
    { label: 'Gebruikersnaam', value: user.getName() },
    { label: 'Geboortedatum', value: userProfile.about?.birthDate + '' },
    { label: 'Geslacht', value: userProfile.about.gender },
  ];

  const detailsItems = [
    {
      label: 'Over mij',
      value: userProfile.about.aboutText ?? '',
      addText: 'Schrijf iets over jezelf',
      inputType: 'textarea',
      onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, about: { ...userProfile.about, aboutText: value } })
    },
    {
      label: 'Relatie status',
      value: userProfile.about.relationshipStatus ?? '',
      addText: 'Voeg jouw relatie status toe',
      inputType: 'select',
      selectOptions: ['Single', 'In een relatie', 'Getrouwd', 'In een open relatie'],
      onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, about: { ...userProfile.about, relationshipStatus: value } })
    },
    {
      label: 'Functie',
      value: userProfile.about.function ?? '',
      addText: 'Voeg je functie toe',
      inputType: 'text',
      onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, about: { ...userProfile.about, function: value } })
    },
  ];

  const appearanceItems = [
    {
      label: 'Bouw',
      value: userProfile.appearance?.build ?? '',
      addText: 'Voeg jouw bouw toe',
      inputType: 'select',
      selectOptions: ['Atletisch', 'Gemiddeld', 'Curvy', 'Fit', 'Overgewicht', 'Dun', 'Anders'],
      onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, appearance: { ...userProfile.appearance, build: value } })
    },
    {
      label: 'Kleur haar',
      value: userProfile.appearance?.hairColor ?? '',
      addText: 'Voeg jouw haarkleur toe',
      inputType: 'select',
      selectOptions: ['Zwart', 'Blond', 'Bruin', 'Grijs', 'Rood', 'Wit', 'Anders'],
      onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, appearance: { ...userProfile.appearance, hairColor: value } })
    },
    {
      label: 'Kleur ogen',
      value: userProfile.appearance?.eyeColor ?? '',
      addText: 'Voeg jouw oogkleur toe',
      inputType: 'select',
      selectOptions: ['Zwart', 'Blauw', 'Bruin', 'Groen', 'Grijs', 'Hazelnoot', 'Meerdere kleuren', 'Anders'],
      onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, appearance: { ...userProfile.appearance, eyeColor: value } })
    },
    {
      label: 'Piercings',
      value: userProfile.appearance?.piercings ?? '',
      addText: 'Voeg jouw piercings toe',
      inputType: 'select',
      selectOptions: ['Veel', 'Een paar', 'Geen'],
      onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, appearance: { ...userProfile.appearance, piercings: value } })
    },
    {
      label: 'Tattoos',
      value: userProfile.appearance?.tattoos ?? '',
      addText: 'Voeg jouw tattoos toe',
      inputType: 'select',
      selectOptions: ['Veel', 'Een paar', 'Geen'],
      onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, appearance: { ...userProfile.appearance, tattoos: value } })
    },
  ];

  const accountItems = [
    { label: 'Geverifieerd account', value: 'Ja' },
  ];

  const closeProfile = () => {
    setCurrentChat((prevState: any) => {
      return {
        ...prevState,
        userProfile: null,
      }
    });
  };

  return (
    <>
      <EditAvatarPopover
        open={editAvatarOpen}
        setOpen={setEditAvatarOpen}
        setAlert={setAlert}
        user={user}
      />

      <Profile
        header={user.getName()}
        avatar={user.getAvatar() ?? defaultAvatar}
        coverImage={user.getAvatar() ?? defaultAvatar}
        coverImageStyle={{ backgroundColor: getActiveColor() }}
        style={{
          width: '100%',
        }}
        avatarStyle={{
          width: '156px',
          height: '156px',
          left: '102px',
        }}
        headerStyle={{
          width: 'auto',
          position: 'absolute',
          textAlign: 'left',
          left: '196px',
          top: '200px'
        }}
        secondaryTextStyle={{
          width: 'auto',
          position: 'absolute',
          textAlign: 'left',
          left: '196px',
          top: '254px'
        }}
        button={
          <>
            <button
              type="button"
              data-radium="true"
              className="edit-avatar-button"
              onClick={() => setEditAvatarOpen(true)}
            >
              <IconEdit color={'white'} />
            </button>

            <ToggleLeftMenuButton
              leftMenuOpen={leftMenuOpen}
              setLeftMenuOpen={setLeftMenuOpen}
              unreadCount={unreadCount}
              isProfile
            />

            <Button
              iconButton
              style={{
                fontSize: '16px',
                position: 'absolute',
                top: '8px',
                right: '8px',
                backgroundColor: 'rgba(21, 21, 21, 0.2)'
              }}
              onClick={closeProfile}
            >
              <IconClose color='white' />
            </Button>
          </>
        }
      >
        <div className="profile-card-container profile-width loggedin-profile">
          <AccountDetailsSection title="Algemeen" items={generalItems} />
          <AccountDetailsSection title="Details" items={detailsItems} />
          <AccountDetailsSection title="Uiterlijk" items={appearanceItems} />
          <AccountDetailsSection title="Account" items={accountItems} />
        </div>
      </Profile>
    </>
  );
}

export default LoggedInUserProfile;