
export const getFontSizeStyle = (fontSize: string) => {
  switch (fontSize) {
    case 'font-medium':
      return {
        fontSize: '1.25em',
      };
    case 'font-large':
      return {
        fontSize: '1.5rem',
      };
    default:
      return {
        fontSize: '1rem',
      };
  }
}