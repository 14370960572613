import Select from "anchor-ui/select";
import MenuItem from "anchor-ui/menu-item";

const VisualsPage = ({
  messageFontSize,
  handleSetMessageFontSize,
  generalFontSize,
  handleSetGeneralFontSize,
}: {
  messageFontSize: string;
  handleSetMessageFontSize: (value: string) => void;
  generalFontSize: string;
  handleSetGeneralFontSize: (value: string) => void;
}) => {

  return (
    <div>
      <h2>Uiterlijk</h2>

      <section className="account-input">

        <section className="setting-select">
          <Select
            label="Berichttekstgrootte"
            value={messageFontSize}
            onChange={(event: object, value: string) => handleSetMessageFontSize(value)}
          >
            <MenuItem text="Normaal" value="font-normal" />
            <MenuItem text="Medium" value="font-medium" />
            <MenuItem text="Groot" value="font-large" />
          </Select>
        </section>

        <section className="setting-select">
          <Select
            label="Algemene tekstgrootte"
            value={generalFontSize}
            onChange={(event: object, value: string) => handleSetGeneralFontSize(value)}
          >
            <MenuItem text="Normaal" value="font-normal" />
            <MenuItem text="Medium" value="font-medium" />
            <MenuItem text="Groot" value="font-large" />
          </Select>
        </section>
      </section>
    </div>
  );
}

export default VisualsPage;