import { useEffect, useRef, useCallback } from 'react';
import { CometChat } from '@cometchat-pro/chat';
import './styles.css';
import { ActiveChannelInterface } from './../../interfaces/activeChannelInterface';
import GroupListComponent from './GroupListComponent';
import UserProfileConvComponent from './UserProfileConvComponent';
import { FriendRequest } from './../../utils/class/FriendRequest';
import { Functions } from 'firebase/functions';

interface GroupMembersListProps {
  currentChat: ActiveChannelInterface;
  loggedInUser: CometChat.User | null;
  friends: CometChat.User[];
  handleRemoveFriend: (friend: CometChat.User) => void;
  handleChangeConversation: (conversation: CometChat.Conversation) => void;
  incomingFriendRequests: FriendRequest[];
  handleAcceptFriendRequest: (request: FriendRequest) => void;
  outgoingFriendRequests: CometChat.User[];
  loadOutgoingFriendRequests: () => void;
  initiateNewConversation: ({ userId, user }: { userId?: string; user?: CometChat.User }) => Promise<void>;
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  handleFriendRequest: (user: CometChat.User) => void;
  hasSentFriendRequest: (user: CometChat.User) => boolean;
  hasReceivedFriendRequest: (user: CometChat.User) => boolean;
  handleAddOrRemoveFriend: (friend: CometChat.User) => void;
  handleBanUser: (user: CometChat.User) => void;
  handleKickUser: (user: CometChat.User, group: CometChat.Group) => void;
  handleMakeUserAdmin: (user: CometChat.User) => void;

  setCurrentChat: (chat: ActiveChannelInterface) => void;
  startPrivateChat: (user: CometChat.User) => Promise<void>;

  members: CometChat.GroupMember[];
  setMembers: (members: CometChat.GroupMember[]) => void;
  chatWithUser: CometChat.User | undefined;
  setChatWithUser: (user: CometChat.User | undefined) => void;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  isSideNav: boolean | undefined;
  functions: Functions;
}

const GroupMembersList = ({
  currentChat,
  loggedInUser,
  friends,
  incomingFriendRequests,
  handleAcceptFriendRequest,
  outgoingFriendRequests,
  blockedUsers,
  handleBlockUser,
  handleFriendRequest,
  hasSentFriendRequest,
  hasReceivedFriendRequest,
  handleAddOrRemoveFriend,
  handleBanUser,
  handleKickUser,

  setCurrentChat,
  startPrivateChat,

  members,
  setMembers,
  chatWithUser,
  setChatWithUser,
  setUserToReport,
  setAlert,

  isSideNav,
  functions,
}: GroupMembersListProps) => {

  const fetchGroupMembers = useCallback(async (currentChat: ActiveChannelInterface) => {
    try {
      const limit = 30;
      const groupMembersRequest = new CometChat.GroupMembersRequestBuilder(currentChat.id)
        .setLimit(limit)
        .build();

      const groupMembers = await groupMembersRequest.fetchNext();
      let fetchedMembers = groupMembers;
      if (currentChat.isOpen) {
        fetchedMembers = groupMembers.filter((member: CometChat.GroupMember) => member.getStatus() === 'online');
      } else {
        // Order the fetchedMembers so that online members are at the top
        fetchedMembers = groupMembers.sort((a: CometChat.GroupMember, b: CometChat.GroupMember) => {
          if (a.getStatus() === 'online') return -1;
          if (b.getStatus() === 'online') return 1;
          return 0;
        });
      }

      if (loggedInUser) {
        const user = new CometChat.GroupMember(loggedInUser.getUid(), CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT);
        user.setAvatar(loggedInUser.getAvatar());
        user.setName(loggedInUser.getName());
        user.setStatus(loggedInUser.getStatus());
        user.setMetadata(loggedInUser.getMetadata());
        user.setRole(loggedInUser.getRole());
        const filteredMembers = fetchedMembers.filter((member: CometChat.GroupMember) => member.getUid() !== user.getUid());
        filteredMembers.unshift(user);

        setMembers(filteredMembers);
      }
    } catch (error) {
      console.error('Error fetching group members:', error);
    }
  }, [loggedInUser, setMembers]);

  const fetchGroupMembersRef = useRef(fetchGroupMembers);
  const setChatWithUserRef = useRef(setChatWithUser);

  useEffect(() => {
    fetchGroupMembersRef.current = fetchGroupMembers;
    setChatWithUserRef.current = setChatWithUser;
  }, [fetchGroupMembers, setChatWithUser]);

  useEffect(() => {
    if (currentChat.id && !currentChat.isGroup) {
      // Fetch user details for private chat
      const chatWithUserId = currentChat.receiverId!;
      const limit = 1;
      const userRequest = new CometChat.UsersRequestBuilder()
        .setLimit(limit)
        .setUIDs([chatWithUserId])
        .build();

      userRequest.fetchNext().then(
        userList => {
          setChatWithUserRef.current(userList[0]);
        },
        error => {
          console.error('User fetching failed with error:', error);
        }
      );
    } else if (currentChat.id && currentChat.isGroup) {
      fetchGroupMembersRef.current(currentChat);
    }
  }, [currentChat]);

  return (
    <div className={`group-members-list ${!isSideNav ? 'widescreen-sidebar' : 'narrowscreen-sidebar'}`}>
      {
        (currentChat.id && !currentChat.isGroup) ? (
          <UserProfileConvComponent
            user={chatWithUser}
            friends={friends}
            handleFriendRequest={handleFriendRequest}
            incomingFriendRequests={incomingFriendRequests}
            handleAcceptFriendRequest={handleAcceptFriendRequest}
            outgoingFriendRequests={outgoingFriendRequests}
          />
        ) : (
          <GroupListComponent
            members={members}
            setMembers={setMembers}
            loggedInUser={loggedInUser}
            friends={friends}
            blockedUsers={blockedUsers}
            handleBlockUser={handleBlockUser}
            hasSentFriendRequest={hasSentFriendRequest}
            hasReceivedFriendRequest={hasReceivedFriendRequest}
            handleAddOrRemoveFriend={handleAddOrRemoveFriend}
            handleBanUser={handleBanUser}
            handleKickUser={handleKickUser}
            // handleMakeUserAdmin={handleMakeUserAdmin}
            currentChat={currentChat}
            setCurrentChat={setCurrentChat}
            startPrivateChat={startPrivateChat}
            setUserToReport={setUserToReport}
            setAlert={setAlert}
            functions={functions}
          />
        )
      }
    </div>
  );
}

export default GroupMembersList;