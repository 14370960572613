import { IconSuccess } from 'anchor-ui/icons';

const ThemePage = ({
  activeColorState,
  handleSetActiveColor
}: {
  activeColorState: string,
  handleSetActiveColor: (color: string) => void,
}) => {

  return (
    <>
      <h2>Kies een thema</h2>

      <div className="theme-selection">
        <button
          className={`blue-button ${activeColorState === '#177FBF' ? 'active' : ''}`}
          onClick={() => handleSetActiveColor('#177FBF')}
        >
          Anchor.Chat
          {activeColorState === '#177FBF' && <IconSuccess color={'white'} style={{ width: '100%' }} />}
        </button>
        <button
          className={`orange-button ${activeColorState === '#f2922c' ? 'active' : ''}`}
          onClick={() => handleSetActiveColor('#f2922c')}
        >
          Oranje
          {activeColorState === '#f2922c' && <IconSuccess color={'white'} style={{ width: '100%' }} />}
        </button>
        <button
          className={`green-button ${activeColorState === '#22ac55' ? 'active' : ''}`}
          onClick={() => handleSetActiveColor('#22ac55')}
        >
          Groen
          {activeColorState === '#22ac55' && <IconSuccess color={'white'} style={{ width: '100%' }} />}
        </button>
      </div>
    </>
  )
}

export default ThemePage;