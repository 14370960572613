import Button from 'anchor-ui/button';
import { IconChannels } from 'anchor-ui/icons';
import Badge from 'anchor-ui/badge';
import { ActiveChannelInterface } from '../../interfaces/activeChannelInterface';
import './styles.css';

const ToggleLeftMenuButton = ({
  leftMenuOpen,
  setLeftMenuOpen,
  unreadCount,
  isProfile
}: {
  leftMenuOpen: boolean;
  setLeftMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  isProfile?: boolean;
}) => {

  const shouldShowBadge = () => {
    let unreadCountLength = 0;
    for (const key in unreadCount) {
      unreadCountLength += unreadCount[key].nr;
    }

    return unreadCountLength > 0;
  }


  return (
    <Button
      iconButton
      onClick={() => setLeftMenuOpen(!leftMenuOpen)}
      className={`button-toggle-menu ${isProfile ? 'profile-toggle-menu' : ''}`}
    >
      <IconChannels color={isProfile && 'white'} />
      {shouldShowBadge() &&
        <Badge
          value={1}
          style={{ top: 0, right: 0, position: 'absolute' }}
        />
      }
    </Button>
  );
}

export default ToggleLeftMenuButton;