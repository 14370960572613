import { SetStateAction, useState } from 'react';
import Modal from 'anchor-ui/modal';
import './addGroupModal.css';
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import Divider from 'anchor-ui/divider';
import Button from 'anchor-ui/button';
import Checkbox from 'anchor-ui/checkbox';
import defaultAvatar from "./../../../assets/default_avatar.jpg";
import Input from 'anchor-ui/input';
import { CometChat } from '@cometchat-pro/chat';
import { notifyMultipleUsersReload } from './../../../components/ConversationSDK/friendRequests';
import { Functions } from 'firebase/functions';

const AddGroupModal = ({
  isOpen,
  closeModal,
  friends,
  refreshConversations,
  setAlert,
  functions,
}: {
  isOpen: boolean;
  closeModal: () => void;
  friends: CometChat.User[];
  refreshConversations: () => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  functions: Functions;
}) => {

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedFriends, setSelectedFriends] = useState<CometChat.GroupMember[]>([]);
  const [groupName, setGroupName] = useState('');

  const handleSelectFriend = (friend: CometChat.User) => {
    if (selectedFriends.some(f => f.getUid() === friend.getUid())) {
      setSelectedFriends(selectedFriends.filter(f => f.getUid() !== friend.getUid()));
    } else if (selectedFriends.length < 50) {

      const member = new CometChat.GroupMember(friend.getUid(), CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT);
      member.setName(friend.getName());
      member.setAvatar(friend.getAvatar());

      setSelectedFriends([...selectedFriends, member]);
    }
  };

  const handleClose = () => {
    setCurrentStep(1);
    setSelectedFriends([]);
    setGroupName('');
    closeModal();
  }

  const handleCreateGroup = async () => {
    try {
      // Create a unique groupid
      let GUID = "group_" + new Date().getTime() + Math.random().toString(36).substring(7);
      let groupType = CometChat.GROUP_TYPE.PRIVATE;
      let password = "";

      if (groupName.length === 0) console.error("Group name is required");

      // Create new group
      let group = new CometChat.Group(GUID, groupName, groupType, password);
      group.setTags(["private"]);
      const createdGroup = await CometChat.createGroup(group);

      // Add members to group
      let members: CometChat.GroupMember[] = [];
      let memberUids: string[] = [];

      selectedFriends.forEach(friend => {
        const member = new CometChat.GroupMember(friend.getUid(), CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT);
        member.setName(friend.getName());
        member.setAvatar(friend.getAvatar());
        members.push(member);
        memberUids.push(friend.getUid());
      });

      await CometChat.addMembersToGroup(createdGroup.getGuid(), members, []);

      // Notify all users to reload their group list
      notifyMultipleUsersReload(
        functions,
        memberUids,
        'group_created',
        createdGroup
      );

      setAlert({ message: 'Groep succesvol aangemaakt', type: 'success' });
      refreshConversations();
      handleClose();
    } catch (error) {
      console.error("Error during group creation or adding members:", error);
    }
  };

  return (
    <Modal
      open={isOpen}
      header={
        <div className="addGroupModalHeaderContainer">
          {currentStep === 1 ?
            <div className="step-one">
              <h1 className="addGroupModalHeaderTitle">Voeg gebruikers toe aan je groep</h1>
              <span className="addGroupModalHeaderSpan">{selectedFriends.length}/50</span>
            </div> :
            <div className='step-two'>
              <h1 className="addGroupModalHeaderTitle">Kies een naam voor je groep</h1>
              <Input
                value={groupName}
                onChange={(e: { target: { value: SetStateAction<string>; }; }) => setGroupName(e.target.value)}
                maxLength={50}
              />
            </div>
          }
        </div>
      }
      actions={
        <>
          <Button flatButton onClick={handleClose}>ANNULEER</Button>
          {
            currentStep === 1 ?
              <Button
                flatButton disabled={!selectedFriends.length}
                onClick={() => setCurrentStep(2)}
              >
                NEXT
              </Button>
              : <Button
                flatButton
                disabled={!groupName}
                onClick={() => handleCreateGroup()}
              >
                NEXT
              </Button>
          }
        </>
      }
      headerStyle={{ fontSize: '16px', }}
    >
      <Divider />

      {friends.length === 0 ?
        <div className='no-friends'>Geen vrienden gevonden...</div> :
        currentStep === 1 ?
          <List style={{ height: 'calc(100% - 52px)' }}>
            {friends.map((friend) => (
              <div key={friend.getUid()}>
                <ListItem
                  primaryText={friend.getName()}
                  avatar={friend.getAvatar() ?? defaultAvatar}
                  rightButton={
                    <Checkbox
                      checked={selectedFriends.some(f => f.getUid() === friend.getUid())}
                      onChange={() => handleSelectFriend(friend)}
                    />
                  }
                />
                <Divider />
              </div>
            ))}
          </List> :
          <List style={{ height: 'calc(100% - 52px)', overflow: 'auto' }}>
            {selectedFriends.map((friend) => (
              <div key={friend.getUid()}>
                <ListItem
                  primaryText={friend.getName()}
                  avatar={friend.getAvatar() ?? defaultAvatar}
                />
                <Divider />
              </div>
            ))}
          </List>
      }

    </Modal>
  );
}

export default AddGroupModal;