import React, { useState } from 'react';
import Input from 'anchor-ui/input';
import Select from 'anchor-ui/select';
import MenuItem from 'anchor-ui/menu-item';
import Button from 'anchor-ui/button';
import { getActiveColor } from './../../../utils/activeColor';

interface AccountDetailsItemProps {
  label: string;
  value: React.ReactNode;
  addText?: string;
  inputType?: string;
  selectOptions?: string[];
  onSave?: (value: string) => Promise<boolean>;
}

const AccountDetailsItem: React.FC<AccountDetailsItemProps> = (item) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(item.value as string);
  const [error, setError] = useState('');

  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Save and close editing mode
  const handleSaveClick = async () => {
    if (!item.onSave) return;

    // Check if the input length is valid (textarea should be below 500, text below 50)
    if ((item.inputType === 'textarea' && inputValue.length > 500) ||
      (item.inputType === 'text' && inputValue.length > 50)) {
      setError('De tekst is te lang');
      return;
    }

    const isSaved = await item.onSave(inputValue);
    if (isSaved) setIsEditing(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setInputValue(item.value as string);
  };

  return (
    <div className="account-details-item">
      <div className="account-details-row">
        <dt className="account-details-label">{item.label}</dt>
        <dd className="account-details-value">
          {isEditing && item.onSave ? (
            <div className='account-input'>
              {item.inputType === 'select' ? (
                <Select
                  onChange={(event: object, value: string) => setInputValue(value)}
                  value={inputValue}
                >
                  {item.selectOptions?.map((option, index) => (
                    <MenuItem key={index} text={option} value={option} />
                  ))}
                  <MenuItem text={'---'} value={''} />
                </Select>
              ) : (
                <Input
                  value={inputValue}
                  name={item.label}
                  placeholder={item.addText}
                  onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setInputValue(e.target.value)}
                  multiLine={item.inputType === 'textarea'}
                  maxRows={item.inputType === 'textarea' ? 4 : 0}
                  maxLength={item.inputType === 'textarea' ? 500 : 50}
                  error={error}
                />
              )}
              <Button onClick={handleSaveClick} disabled={inputValue === item.value}>
                Bewaar
              </Button>
              <Button inverted onClick={handleCancelClick}>
                Annuleer
              </Button>

              {item.inputType !== 'select' &&
                <span className='profile-char-count'>{inputValue.length}/{item.inputType === 'textarea' ? 500 : 50}</span>
              }
            </div>
          ) : (
            <>
              {item.value}
              {item.value && item.addText && (
                <div className="account-details-edit cursor-pointer" onClick={handleEditClick}>
                  <span style={{ color: getActiveColor() }}>Bewerk</span>
                </div>
              )}
              {!item.value && (
                <span className="cursor-pointer" style={{ color: getActiveColor() }} onClick={handleEditClick}>
                  + {item.addText}
                </span>
              )}
            </>
          )}
        </dd>
      </div >
    </div >
  );
};

interface AccountDetailsSectionProps {
  title: string;
  items: AccountDetailsItemProps[];
}

const AccountDetailsSection: React.FC<AccountDetailsSectionProps> = ({ title, items }) => (
  <section className="account-details-container">
    <h1 className="account-details-title" style={{ color: getActiveColor() }}>{title}</h1>
    <dl className="account-details-list">
      {items.map((item, index) => (
        <AccountDetailsItem key={index} {...item} />
      ))}
    </dl>
  </section>
);

export default AccountDetailsSection;