import { useEffect, useState } from "react";
import ChatComponent from "../ChatComponent";
import { ActiveChannelInterface } from "./../../interfaces/activeChannelInterface";
import GroupMembersList from "../GroupMembersList";
import { CometChat } from '@cometchat-pro/chat';
import { FriendRequest } from "./../../utils/class/FriendRequest";
import {
  deleteFriendRequestOrigin,
  deleteIncomingFriendRequest,
  deleteCustomMessage,
} from "./friendRequests";
import { NotificationInterface } from "./../../interfaces/notificationInterface";
import UserProfile from "./../../components/UserProfile";
import ReportUserModal from "./../../components/ReportModal/ReportUserModal";
import LoggedInUserProfile from "./../../components/UserProfile/LoggedInUserProfile";
import Timeline from "./../../components/Timeline";
import { GroupConversation } from "./../../utils/class/GroupConversation";
import Menu from 'anchor-ui/menu';
import LeftSidebarMenu from "../LeftSideBarMenu";
import SettingsPage from "../SettingsPage";
import { Functions } from "firebase/functions";

const ConversationSDK = ({
  setNotifications,
  unreadCount,
  setUnreadCount,
  currentChat,
  setCurrentChat,
  activeChannels,
  handleLeaveChannel,

  handleChangeConversation,
  handleGroupConversationClick,
  activeTabIndex,
  setActiveTabIndex,
  loggedInUser,

  conversations,
  setConversations,
  initialConversation,
  setInitialConversation,
  refreshConversations,

  incomingFriendRequests,
  loadIncomingFriendRequests,
  outgoingFriendRequests,
  loadOutgoingFriendRequests,
  blockedUsers,
  handleBlockUser,

  friends,
  loadFriends,
  setFriends,
  handleRemoveFriend,
  handleAcceptFriendRequest,
  handleFriendRequest,
  hasSentFriendRequest,
  hasReceivedFriendRequest,
  handleAddOrRemoveFriend,
  handleBanUser,
  handleKickUser,
  handleMakeUserAdmin,

  members,
  setMembers,
  chatWithUser,
  setChatWithUser,
  channels,
  setAlert,

  messages,
  setMessages,
  activeColorState,
  setActiveColorState,
  messageFontSize,
  setMessageFontSize,
  generalFontSize,
  setGeneralFontSize,

  showLoadMostRecentMsgBtn,
  setShowLoadMostRecentMsgBtn,
  functions,
  handleLogout,
  handleChangeChannel,
}: {
  setNotifications: React.Dispatch<React.SetStateAction<NotificationInterface[] | null>>
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } },
  setUnreadCount: React.Dispatch<React.SetStateAction<{ [id: string]: { nr: number, chat: ActiveChannelInterface } }>>,
  currentChat: ActiveChannelInterface,
  setCurrentChat: React.Dispatch<React.SetStateAction<ActiveChannelInterface>>,
  activeChannels: ActiveChannelInterface[],
  handleLeaveChannel: (channel: ActiveChannelInterface) => void,

  handleChangeConversation: (conversation: CometChat.Conversation) => void,
  handleGroupConversationClick: (group: CometChat.Group) => Promise<void>,
  activeTabIndex: number,
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>,
  loggedInUser: CometChat.User | null,

  conversations: CometChat.Conversation[] | GroupConversation[],
  setConversations: React.Dispatch<React.SetStateAction<CometChat.Conversation[] | GroupConversation[]>>,
  initialConversation: CometChat.Conversation | null,
  setInitialConversation: React.Dispatch<React.SetStateAction<CometChat.Conversation | null>>,
  refreshConversations: () => void,

  incomingFriendRequests: FriendRequest[],
  loadIncomingFriendRequests: () => void,
  outgoingFriendRequests: FriendRequest[],
  loadOutgoingFriendRequests: () => void,
  blockedUsers: CometChat.User[],
  handleBlockUser: (userId: string) => void,

  friends: CometChat.User[],
  loadFriends: () => void,
  setFriends: React.Dispatch<React.SetStateAction<CometChat.User[]>>,
  handleRemoveFriend: (friend: CometChat.User) => void,
  handleAcceptFriendRequest: (request: FriendRequest) => void,
  handleFriendRequest: (user: CometChat.User) => Promise<void>,
  hasSentFriendRequest: (user: CometChat.User) => boolean,
  hasReceivedFriendRequest: (user: CometChat.User) => boolean,
  handleAddOrRemoveFriend: (friend: CometChat.User) => void,
  handleBanUser: (user: CometChat.User) => void,
  handleKickUser: (user: CometChat.User, group: CometChat.Group) => void,
  handleMakeUserAdmin: (user: CometChat.User) => void,

  members: CometChat.GroupMember[],
  setMembers: React.Dispatch<React.SetStateAction<CometChat.GroupMember[]>>,
  chatWithUser: CometChat.User | undefined,
  setChatWithUser: React.Dispatch<React.SetStateAction<CometChat.User | undefined>>,
  channels: CometChat.Group[],
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>

  messages: CometChat.BaseMessage[];
  setMessages: React.Dispatch<React.SetStateAction<CometChat.BaseMessage[]>>;
  activeColorState: string;
  setActiveColorState: (color: string) => void;
  messageFontSize: string;
  setMessageFontSize: (fontSize: string) => void;
  generalFontSize: string;
  setGeneralFontSize: (fontSize: string) => void;

  showLoadMostRecentMsgBtn: boolean;
  setShowLoadMostRecentMsgBtn: React.Dispatch<React.SetStateAction<boolean>>;
  functions: Functions;
  handleLogout: () => void;

  handleChangeChannel: (channel: ActiveChannelInterface) => void;
}) => {

  const [lastConversationMsg, setLastConversationMsg] = useState<{ conversationId: string, receiverId: string, message: string }>({ conversationId: '', receiverId: '', message: '' }); // Displayed in ConversationListComponent

  const [friendToDelete, setFriendToDelete] = useState<CometChat.User | null>(null);
  const [userToReport, setUserToReport] = useState<{ user: CometChat.User, guid?: string } | null>(null);

  const [leftMenuOpen, setLeftMenuOpen] = useState(false);
  const [rightMenuOpen, setRightMenuOpen] = useState(false);

  const initiateNewConversation = async ({ userId, user }: { userId?: string, user?: CometChat.User }) => {
    if (!user && userId) user = await CometChat.getUser(userId);

    if (user) {
      const newChat = {
        id: user.getUid(),
        receiverId: user.getUid(),
        icon: user.getAvatar(),
        name: user.getName(),
        joinedAt: Date.now(),
        isGroup: false
      };
      setCurrentChat(newChat);

      const lastMsg = new CometChat.TextMessage(
        user.getUid(),
        'Init',
        CometChat.RECEIVER_TYPE.USER
      );

      // Create blank conversation object
      const newConversation = new CometChat.Conversation(
        user.getUid(),
        CometChat.RECEIVER_TYPE.USER,
        lastMsg,
        user,
        0,
        []
      );

      setInitialConversation(newConversation);
      setActiveTabIndex(1);
    }
  };

  const handleDenyFriendRequest = async (request: FriendRequest) => {
    if (!loggedInUser) return;

    await deleteIncomingFriendRequest(functions, request, 'friend_request_denied');

    loadIncomingFriendRequests();
  };

  const handleCancelFriendRequest = async (request: FriendRequest) => {
    if (!loggedInUser) return;

    await deleteCustomMessage(functions, request.messageId);
    await deleteFriendRequestOrigin(functions, request.getUid());

    setAlert({ message: 'Vriendschapsverzoek geannuleerd', type: 'success' });

    loadOutgoingFriendRequests();
  };

  const startPrivateChat = async (user: CometChat.User) => {
    try {
      const receiverID = user.getUid();
      const receiverType = CometChat.RECEIVER_TYPE.USER;

      CometChat.getConversation(receiverID, receiverType).then(
        conversation => {
          handleChangeConversation(conversation);
        },
        error => {
          // If conversation doesn't exist, create a new one
          initiateNewConversation({ user: user });
        }
      );

    } catch (error) {
      console.error("Error starting private chat:", error);
      setAlert({ message: 'Er is iets misgegaan bij het starten van een privéchat, probeer het opnieuw', type: 'error' });
    }
  };

  const showUserProfile = (user: CometChat.User) => {
    setCurrentChat((prev: any) => ({ ...prev, userProfile: user }));
  }

  useEffect(() => {
    // If initialConversation is set, check if it exists in conversations, if it does, remove it
    if (initialConversation) {
      let existingConversation: CometChat.Conversation | null = null;
      existingConversation = (conversations as (CometChat.Conversation | CometChat.Group)[])
        .filter((conversation): conversation is CometChat.Conversation => conversation instanceof CometChat.Conversation)
        .find((conversation: CometChat.Conversation) => {
          const conversationWith = conversation.getConversationWith();
          const initialConversationWith = initialConversation.getConversationWith();

          if ('getUid' in conversationWith && 'getUid' in initialConversationWith) {
            return conversationWith.getUid() === initialConversationWith.getUid();
          }
          return false;
        }) || null;

      if (existingConversation) {
        // If conversation exists, set it as the current chat if it's the same as the initial conversation
        const conversationWith = existingConversation.getConversationWith();
        const initialConversationWith = initialConversation.getConversationWith();

        if ('getUid' in conversationWith && 'getUid' in initialConversationWith) {
          const existingConversationUid = (conversationWith as CometChat.User).getUid();
          const initialConversationUid = (initialConversationWith as CometChat.User).getUid();

          if (existingConversationUid === initialConversationUid) {
            handleChangeConversation(existingConversation);
          }
        }

        setInitialConversation(null);
      }
    }
  }, [conversations, initialConversation, currentChat]);

  const leftSidebarMenuProps = {
    activeChannels,

    currentChat,
    setCurrentChat,
    handleLeaveChannel,

    conversations,
    setConversations,
    initialConversation,
    refreshConversations,
    lastConversationMsg,
    handleGroupConversationClick,

    friends,
    loadFriends,
    setFriends,
    handleRemoveFriend,
    incomingFriendRequests,
    loadIncomingFriendRequests,
    outgoingFriendRequests,
    loadOutgoingFriendRequests,

    handleAcceptFriendRequest,
    handleDenyFriendRequest,
    handleCancelFriendRequest,

    handleConversationClick: handleChangeConversation,

    unreadCount,
    setUnreadCount,

    activeTabIndex,
    setActiveTabIndex,

    setNotifications,
    loggedInUser,

    channels,
    startPrivateChat,
    blockedUsers,
    handleBlockUser,

    userToReport,
    setUserToReport,
    members,
    setAlert,

    friendToDelete,
    setFriendToDelete,
    showUserProfile,

    isSideNav: false,

    handleLogout,

    handleChangeChannel,
    functions,
  }

  const groupMembersListProps = {
    currentChat,
    loggedInUser,
    friends,
    handleRemoveFriend,
    handleChangeConversation,

    incomingFriendRequests,
    handleAcceptFriendRequest,
    outgoingFriendRequests,
    loadOutgoingFriendRequests,
    initiateNewConversation,
    blockedUsers,
    handleBlockUser,
    handleFriendRequest,
    hasSentFriendRequest,
    hasReceivedFriendRequest,
    handleAddOrRemoveFriend,
    handleBanUser,
    handleKickUser,
    handleMakeUserAdmin,
    setCurrentChat,
    startPrivateChat,
    members,
    setMembers,
    chatWithUser,
    setChatWithUser,
    setUserToReport,
    setAlert,
    isSideNav: false,
    functions,
  }

  return (
    <>
      <ReportUserModal
        userToReport={userToReport}
        setUserToReport={setUserToReport}
        setAlert={setAlert}
      />

      <Menu
        closeMenu={() => setLeftMenuOpen(!leftMenuOpen)}
        open={leftMenuOpen}
        style={{ backgroundColor: 'transparent' }}
      >
        <LeftSidebarMenu {...leftSidebarMenuProps} isSideNav />
      </Menu>

      <Menu
        closeMenu={() => setRightMenuOpen(!rightMenuOpen)}
        open={rightMenuOpen}
        position="right"
        style={{ backgroundColor: 'transparent' }}
      >
        <GroupMembersList {...groupMembersListProps} isSideNav />
      </Menu>

      <LeftSidebarMenu {...leftSidebarMenuProps} />

      {currentChat.userProfile ?
        <div className="user-profile-section">
          {currentChat.userProfile && currentChat.userProfile.getUid() === loggedInUser?.getUid() ?
            <>
              {currentChat.showSettingPage ?
                <SettingsPage
                  setCurrentChat={setCurrentChat}
                  loggedInUser={loggedInUser}
                  setAlert={setAlert}

                  activeColorState={activeColorState}
                  setActiveColorState={setActiveColorState}
                  messageFontSize={messageFontSize}
                  setMessageFontSize={setMessageFontSize}
                  generalFontSize={generalFontSize}
                  setGeneralFontSize={setGeneralFontSize}

                  blockedUsers={blockedUsers}
                  handleBlockUser={handleBlockUser}
                  functions={functions}
                  handleLogout={handleLogout}
                /> :
                <LoggedInUserProfile
                  user={currentChat.userProfile}
                  setCurrentChat={setCurrentChat}

                  leftMenuOpen={leftMenuOpen}
                  setLeftMenuOpen={setLeftMenuOpen}
                  unreadCount={unreadCount}
                  setAlert={setAlert}
                />
              }
            </> :
            <UserProfile
              user={currentChat.userProfile}
              setCurrentChat={setCurrentChat}
              startPrivateChat={startPrivateChat}

              friends={friends}
              hasSentFriendRequest={hasSentFriendRequest}
              hasReceivedFriendRequest={hasReceivedFriendRequest}
              handleAddOrRemoveFriend={handleAddOrRemoveFriend}

              leftMenuOpen={leftMenuOpen}
              setLeftMenuOpen={setLeftMenuOpen}
              unreadCount={unreadCount}
            />
          }
        </div> :
        currentChat.id ? (
          <>
            <ChatComponent
              currentChat={currentChat}
              setCurrentChat={setCurrentChat}
              conversations={conversations}
              setConversations={setConversations}
              setLastConversationMsg={setLastConversationMsg}
              loggedInUser={loggedInUser}
              loadIncomingFriendRequests={loadIncomingFriendRequests}
              loadOutgoingFriendRequests={loadOutgoingFriendRequests}
              setNotifications={setNotifications}
              blockedUsers={blockedUsers}
              handleBlockUser={handleBlockUser}

              friends={friends}
              hasSentFriendRequest={hasSentFriendRequest}
              hasReceivedFriendRequest={hasReceivedFriendRequest}
              handleAddOrRemoveFriend={handleAddOrRemoveFriend}

              setUserToReport={setUserToReport}
              setAlert={setAlert}

              messages={messages}
              setMessages={setMessages}

              leftMenuOpen={leftMenuOpen}
              setLeftMenuOpen={setLeftMenuOpen}
              rightMenuOpen={rightMenuOpen}
              setRightMenuOpen={setRightMenuOpen}

              unreadCount={unreadCount}

              groupMembers={members}
              chatWithUser={chatWithUser}
              showLoadMostRecentMsgBtn={showLoadMostRecentMsgBtn}
              setShowLoadMostRecentMsgBtn={setShowLoadMostRecentMsgBtn}
            />

            <GroupMembersList {...groupMembersListProps} />

          </>
        ) : loggedInUser &&
        <Timeline
          loggedInUser={loggedInUser}
          functions={functions}
          friends={friends}
          showUserProfile={showUserProfile}
          startPrivateChat={startPrivateChat}
          setUserToReport={setUserToReport}
          setFriendToDelete={setFriendToDelete}
          blockedUsers={blockedUsers}
          handleBlockUser={handleBlockUser}

          conversations={conversations}
          unreadCount={unreadCount}
          handleConversationClick={handleChangeConversation}
          handleGroupConversationClick={handleGroupConversationClick}
          initialConversation={initialConversation}
          currentChat={currentChat}
          setCurrentChat={setCurrentChat}
          refreshConversations={refreshConversations}
          lastConversationMsg={lastConversationMsg}
          setUnreadCount={setUnreadCount}
          setAlert={setAlert}

          leftMenuOpen={leftMenuOpen}
          setLeftMenuOpen={setLeftMenuOpen}
        />
      }
    </>
  );
};

export default ConversationSDK;