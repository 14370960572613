import './styles.css';
import chatplazaLogoSvg from '../../assets/chatplaza-logo.svg';
import { IconMenu } from 'anchor-ui/icons';
import { useEffect, useState } from 'react';
import SideNav from './SideNav';

const Navigation = () => {
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const pathname = window.location.pathname;

  return (
    <nav className="navigation">

      <SideNav
        sideNavOpen={sideNavOpen}
        setSideNavOpen={setSideNavOpen}
        activePage={pathname}
      />

      <section className="page-container">
        <div className='container-content'>

          <a href="/">
            <img src={chatplazaLogoSvg} alt="ChatPlaza logo" />
          </a>
          <ul className='widescreen'>
            <li>
              <a href='/' className={`${pathname === '/' ? 'active' : ''}`}>HOME</a>
            </li>
            <li>
              <a href='/chat'>CHATTEN</a>
            </li>
            <li>
              <a href='/flirten' className={`${pathname === '/flirten' ? 'active' : ''}`}>FLIRTEN</a>
            </li>
            <li>
              <a href='https://ds1.nl/c/?si=51&li=1619882&wi=250877&ws=' target="_blank">DATEN</a>
            </li>
            <li>
              <a href="https://www.pikantcams.nl/nl?pi=link_id_253" target="_blank">
                WEBCAMS 18+
              </a>
            </li>
            <li>
              <a href='/blog' className={`${pathname.includes('/blog') ? 'active' : ''}`}>BLOG</a>
            </li>
          </ul>

          <span className='hamburger' onClick={() => setSideNavOpen(true)}>
            <IconMenu color={'#4D4D4D'} />
          </span>
        </div>

      </section>
    </nav>
  );
}

export default Navigation;