import Layout from "../Layout";
import termsImg from '../../assets/terms.jpg';

const ChatRulesPage = () => {

  return (
    <Layout heroText="Chatregels" heroImg={termsImg}>
      <div className="page-container">
        <div className='container-content'>
          <article className="terms-page">
            <section>
              <h1>Chat Regels</h1>
              <p>
                Door deel te nemen aan de chat ga je akkoord met onderstaande regels. Deze regels zijn opgesteld om de chatomgeving veilig, respectvol en aangenaam te houden voor alle gebruikers. Voor vragen of opmerkingen kun je terecht op onze contactpagina.
              </p>

              <h2>1. Gebruikersnaam</h2>
              <ul>
                <li>Kies een gebruikersnaam die niet aanstootgevend of kwetsend is.</li>
                <li>Discriminerende, racistische, seksueel expliciete of anderszins beledigende namen zijn niet toegestaan.</li>
              </ul>

              <h2>2. Strikte Naleving van Wet- en Regelgeving</h2>
              <ul>
                <li>Illegale activiteiten, het delen van kinderporno of het aanzetten tot pedofilie zijn absoluut verboden.</li>
                <li>Bij constatering wordt onmiddellijk aangifte gedaan bij de politie en wordt melding gemaakt bij relevante instanties (bijv. Meldpunt Kinderporno).</li>
                <li>De chat voldoet aan de actuele (inter)nationale wet- en regelgeving, inclusief richtlijnen voor online platformen en privacywetgeving (zoals de AVG).</li>
              </ul>

              <h2>3. Geen Reclame of Commerciële Activiteiten</h2>
              <ul>
                <li>Het is niet toegestaan producten, diensten of andere commerciële zaken aan te bieden, te verkopen of te promoten.</li>
                <li>Ongevraagd plaatsen van reclameboodschappen, merkvermeldingen, affiliate links of overige commerciële content is verboden.</li>
              </ul>

              <h2>4. Geen Discriminatie, Racisme of Haatzaaien</h2>
              <ul>
                <li>Discriminerende, racistische of haatdragende uitlatingen zijn niet toegestaan.</li>
                <li>Het aanzetten tot geweld, extremisme of andere schadelijke activiteiten is verboden.</li>
              </ul>

              <h2>5. Geen Ongewenste Links en Content</h2>
              <ul>
                <li>Het plaatsen van links is in principe verboden, met uitzondering van zorgvuldig geselecteerde, toegestane YouTube-links.</li>
                <li>Het verspreiden van ongepaste, schadelijke, misleidende of illegale content (waaronder deepfakes, malware of auteursrechtelijk beschermd materiaal zonder toestemming) is niet toegestaan.</li>
              </ul>

              <h2>6. Respectvol Taalgebruik en Gedrag</h2>
              <ul>
                <li>Geen scheldwoorden met ziektes, extreme beledigingen of grof taalgebruik.</li>
                <li>Behandel anderen met respect, ook als je het niet met elkaar eens bent.</li>
                <li>Cyberpesten, stalken, intimideren of andere vormen van agressief of opdringerig gedrag zijn verboden.</li>
              </ul>

              <h2>7. Geen Sekschat, Naaktfoto’s of Ongepaste Content buiten Geselecteerde Ruimtes</h2>
              <ul>
                <li>Seksueel expliciete content, naaktfoto’s of seksueel getinte chats zijn niet toegestaan in openbare kanalen of ongepaste ruimtes.</li>
                <li>Als het platform specifieke, duidelijk gemarkeerde 18+ ruimtes faciliteert, gelden daar strikte leeftijdscontroles en aanvullende regels.</li>
              </ul>

              <h2>8. Geen Ongeautoriseerde Toegangsmethoden of Hulpmiddelen</h2>
              <ul>
                <li>Het is niet toegestaan in te loggen of toegang te verkrijgen tot de chat via andere methoden dan de door de website ondersteunde interfaces.</li>
                <li>Misbruik van API’s, bots of andere externe toepassingen zonder toestemming van de beheerders is verboden.</li>
              </ul>

              <h2>9. Geen Overdadige Herhalingen of Spam</h2>
              <ul>
                <li>Herhaal geen zinnen, tekens, symbolen, emoji’s, GIF’s of afbeeldingen op een storende manier.</li>
                <li>Vermijd spammen, flooden en andere activiteiten die het normale verloop van het gesprek verstoren.</li>
              </ul>

              <h2>10. Geen Contact- of Persoonsgegevens in Openbare Kanalen</h2>
              <ul>
                <li>Het openbaar delen van persoonlijke gegevens, zoals telefoonnummers, e-mailadressen, fysieke adressen, social media accounts of andere contactinformatie, is verboden.</li>
                <li>Bescherm je eigen privacy en die van anderen.</li>
              </ul>

              <h2>11. Admins, Moderatie en Handhaving</h2>
              <ul>
                <li>De beheerders (admins) zijn verantwoordelijk voor handhaving van de regels. Zij kunnen waarschuwen, kicken of bannen wanneer regels worden overtreden.</li>
                <li>Discussie over de regels of sancties in de openbare chat is niet toegestaan. Gebruik hiervoor het contactformulier.</li>
                <li>De admins hebben het laatste woord bij interpretatie van de regels en kunnen in uitzonderlijke gevallen aanvullende maatregelen treffen.</li>
              </ul>

              <h2>12. Geen Ongewenste Privéberichten</h2>
              <ul>
                <li>Vraag eerst om toestemming voordat je iemand een privébericht stuurt.</li>
                <li>Respecteer de wens van anderen om geen privéberichten te ontvangen. De functie om privéberichten uit te schakelen kan worden gebruikt.</li>
              </ul>

              <h2>13. Onvoorzien</h2>
              <ul>
                <li>Wanneer zich een situatie voordoet die niet in deze regels is voorzien, hebben de admins de bevoegdheid om een bindende oplossing te bedenken.</li>
                <li>Houd rekening met toekomstige updates van de regels om te voldoen aan nieuwe wetgeving, richtlijnen of platformvereisten.</li>
              </ul>

              <p>
                Door deze regels na te leven draag je bij aan een veilige, respectvolle en prettige chatomgeving. Overtredingen kunnen leiden tot waarschuwingen, tijdelijke uitsluitingen of permanente verwijdering van het platform. Bedankt voor je medewerking!
              </p>
            </section>
          </article>
        </div>
      </div>
    </Layout>

  )
}

export default ChatRulesPage;