import './styles.css';
import chatplazaLogoSmallSvg from '../../assets/chatplaza-logo-small.svg';
import { IconClose } from 'anchor-ui/icons';
import Menu from 'anchor-ui/menu';
import Button from 'anchor-ui/button';
import MenuItem from 'anchor-ui/menu-item';
import Divider from 'anchor-ui/divider';
import { useNavigate } from 'react-router-dom';

const SideNav = (
  {
    sideNavOpen,
    setSideNavOpen,
    activePage,
  }: {
    sideNavOpen: boolean,
    setSideNavOpen: React.Dispatch<React.SetStateAction<boolean>>,
    activePage: string,
  }) => {

  const navigate = useNavigate();

  return (
    <Menu
      open={sideNavOpen}
      closeMenu={() => setSideNavOpen(false)}
      position="right"
      className="nav-sidebar"
    >
      <div className='sidebar-content'>
        <div className='top'>
          <img src={chatplazaLogoSmallSvg} alt="ChatPlaza logo" />
          <Button iconButton onClick={() => setSideNavOpen(false)}>
            <IconClose />
          </Button>
        </div>
        <Divider style={{ marginTop: '20px' }} />
        <MenuItem text='HOME'
          active={activePage === '/'}
          activeStyle={{ color: '#FF9700' }}
          style={{ color: '#4D4D4D' }}
          onClick={() => navigate('/')} />
        <Divider />
        <MenuItem text='CHATTEN'
          active={activePage === '/chat'}
          activeStyle={{ color: '#FF9700' }}
          style={{ color: '#4D4D4D' }}
          onClick={() => navigate('/chat')} />
        <Divider />
        <MenuItem text='FLIRTEN'
          active={activePage === '/flirten'}
          activeStyle={{ color: '#FF9700' }}
          style={{ color: '#4D4D4D' }}
          onClick={() => navigate('/flirten')} />
        <Divider />
        <MenuItem text='DATEN'
          style={{ color: '#4D4D4D' }}
          onClick={() => navigate('https://ds1.nl/c/?si=51&li=1619882&wi=250877&ws=')} />
        <Divider />
        <MenuItem text='WEBCAMS 18+'
          style={{ color: '#4D4D4D' }}
          onClick={() => navigate('https://www.pikantcams.nl/nl?pi=link_id_253')} />
        <Divider />
        <MenuItem text='BLOG'
          active={activePage === '/blog'}
          activeStyle={{ color: '#FF9700' }}
          style={{ color: '#4D4D4D' }}
          onClick={() =>  navigate('/blog')} />
      </div>
    </Menu>
  )
}

export default SideNav;