import Modal from 'anchor-ui/modal';
import './addGroupModal.css';
import Button from 'anchor-ui/button';
import { CometChat } from '@cometchat-pro/chat';
import { notifyMultipleUsersReload } from '../../ConversationSDK/friendRequests';
import { Functions } from 'firebase/functions';
import { ActiveChannelInterface } from '../../../interfaces/activeChannelInterface';

const RemoveConversationModal = ({
  isOpen,
  closeModal,
  conversation,
  refreshConversations,
  currentChat,
  setCurrentChat,
  setUnreadCount,
  loggedInUser,
  setAlert,
  functions,
}: {
  isOpen: boolean,
  closeModal: () => void,
  conversation: any,
  refreshConversations: () => void,
  currentChat: ActiveChannelInterface,
  setCurrentChat: (chat: ActiveChannelInterface) => void,
  setUnreadCount: React.Dispatch<React.SetStateAction<{ [key: string]: number; }>>,
  loggedInUser: CometChat.User,
  setAlert: (alert: { message: string, type: string }) => void,
  functions: Functions,
}) => {

  const handleClose = () => {
    closeModal();
  }

  const deleteGroup = () => {
    // Get members of the group
    const guid = conversation.guid;
    let members: string[] = [];

    const groupMembersRequest = new CometChat.GroupMembersRequestBuilder(guid).setLimit(100).build();

    groupMembersRequest.fetchNext().then(
      groupMembers => {
        members = groupMembers.map(member => member.getUid());
      },
      error => {
        console.error("Group member list fetching failed with error:", error);
      }
    );

    CometChat.deleteGroup(guid).then(
      () => {
        if (conversation.guid === currentChat.id) {
          setCurrentChat({ id: '', name: '', icon: '', joinedAt: 0, isGroup: false });
        }
        setAlert({ message: 'Groep verwijderd', type: 'success' });
        notifyMultipleUsersReload(functions, members, 'group_deleted', conversation); // Notify all users to reload their group list
        refreshConversations();
        closeModal();
      },
      error => {
        console.error("Failed to delete group:", error);
        setAlert({ message: 'Er is iets fout gegaan bij het verwijderen van de groep', type: 'error' });
      }
    );
  }

  const leaveGroup = async () => {
    try {
      await CometChat.leaveGroup(conversation.guid);

      if (conversation.guid === currentChat.id) {
        setCurrentChat({ id: '', name: '', icon: '', joinedAt: 0, isGroup: false });
      }

      setAlert({ message: 'Je hebt de groep verlaten', type: 'success' });
      refreshConversations();
      closeModal();
    } catch (error) {
      console.error("Failed to leave group:", error);
      setAlert({ message: 'Er is iets fout gegaan bij het verlaten van de groep', type: 'error' });
    }
  };

  const handleLeaveGroup = () => {
    const isGroupOwner = conversation.owner === loggedInUser.getUid();

    if (isGroupOwner) deleteGroup();
    else leaveGroup();
  }

  const deleteConversation = async () => {
    try {
      // Set all messages as read
      const lastMessageId = conversation.lastMessage?.id;
      const senderId = conversation.conversationWith.uid;
      const receiverType = conversation.conversationType;

      if (lastMessageId) await CometChat.markAsRead(lastMessageId, loggedInUser.getUid(), receiverType, senderId);

      // Remove the conversation from the unread count
      setUnreadCount(prevState => {
        const newState = { ...prevState };
        delete newState[conversation.conversationId];
        return newState;
      });

      // Delete the conversation
      await CometChat.deleteConversation(conversation.conversationWith.uid, conversation.conversationType)
        .catch(error => {
          if (error.code !== 'ERR_CONVERSATION_NOT_ACCESSIBLE') {
            throw error;
          }
        })

      if (conversation.conversationId === currentChat.id) {
        setCurrentChat({ id: '', name: '', icon: '', joinedAt: 0, isGroup: false });
      }

      setAlert({ message: 'Gesprek verwijderd', type: 'success' });
      refreshConversations();
      closeModal();
    } catch (error) {
      console.error("Error marking messages as read or deleting conversation:", error);
      setAlert({ message: 'Er is iets fout gegaan bij het verwijderen van het gesprek', type: 'error' });
    }
  };

  const handleRemoveConversation = () => {
    const isGroup = conversation.guid;

    if (isGroup) handleLeaveGroup();
    else deleteConversation();
  }

  return (
    <Modal
      open={isOpen}
      header={
        <span>Sluit gesprek</span>
      }
      actions={
        <>
          <Button flatButton onClick={handleClose}>ANNULEER</Button>

          <Button flatButton onClick={handleRemoveConversation}>SLUIT</Button>

        </>
      }
      headerStyle={{ fontSize: '16px' }}
    >
      Weet je zeker dat je dit kanaal wilt sluiten? Hiermee wordt ook de geschiedenis van het kanaal gewist.
    </Modal>
  );
}

export default RemoveConversationModal;