import { SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { confirmPasswordReset, Auth } from 'firebase/auth';
import { validatePassword } from '../../utils/validatePassword';
import ChatPlazaLogo from '../../assets/chatplaza-logo.svg';

import Input from 'anchor-ui/input';
import Card from 'anchor-ui/card';
import CardHeader from 'anchor-ui/card-header';
import Button from 'anchor-ui/button';

const ResetPasswordComponent = ({ auth, oobCode, email }: { auth: Auth, oobCode: string; email: string | null }) => {

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (!oobCode) throw new Error('Ongeldige of verlopen wachtwoord reset code');

      if (newPassword !== confirmPassword) {
        setError('Wachtwoorden komen niet overeen.');
        return;
      }

      const passwordValidationError = await validatePassword(auth, newPassword);
      if (passwordValidationError) {
        setError(passwordValidationError);
        return;
      }

      await confirmPasswordReset(auth, oobCode, newPassword);
      setSuccess(true);
    } catch (error) {
      setError('Fout bij het resetten van het wachtwoord');
    }
  };

  return (
    <section className="action-page">
      <Card className="action-card">
        <img src={ChatPlazaLogo} className="logo" />
        <CardHeader title="Reset Wachtwoord" style={{ alignSelf: "center" }} />
        {success ? (
          <>
            <p>Wachtwoord succesvol gereset!</p>
            <Button
              inverted
              onClick={() => navigate('/login')}
              style={{ width: '100%' }}
            >
              Naar login
            </Button>
          </>
        ) : (
          <form onSubmit={handleResetPassword} className="action-form">
            <p>Voer een nieuw wachtwoord in voor {email}</p>
            <Input
              label="Nieuw wachtwoord"
              type="password"
              placeholder="Nieuw wachtwoord"
              value={newPassword}
              onChange={(e: { target: { value: SetStateAction<string> } }) => setNewPassword(e.target.value)}
              className="action-input"
              required
              maxLength={256}
            />
            <Input
              label="Bevestig wachtwoord"
              type="password"
              placeholder="Bevestig wachtwoord"
              value={confirmPassword}
              onChange={(e: { target: { value: SetStateAction<string> } }) => setConfirmPassword(e.target.value)}
              className="action-input"
              required
              maxLength={256}
            />
            <Button
              type="submit"
              className="action-button"
              disabled={!newPassword || !confirmPassword}
            >
              Reset wachtwoord
            </Button>
            {error && <p className="action-error-message">{error}</p>}
          </form>
        )}
      </Card>
    </section>
  );
}

export default ResetPasswordComponent;