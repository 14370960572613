import { useState } from "react";
import { CometChat } from "@cometchat-pro/chat";
import { ActiveChannelInterface } from "./../../interfaces/activeChannelInterface";
import Modal from 'anchor-ui/modal';
import Button from 'anchor-ui/button';
import Divider from 'anchor-ui/divider';
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import defaultAvatar from "./../../assets/default_avatar.jpg";
import Checkbox from 'anchor-ui/checkbox';
import { notifyMultipleUsersReload } from "./../../components/ConversationSDK/friendRequests";
import { checkIfUserIsBlocked } from "../../utils/checkIfUserIsBlocked";
import { Functions } from "firebase/functions";

const AddUserToGroupModal = ({
  isOpen,
  setIsOpen,
  friends,
  members,
  setMembers,
  currentChat,
  blockedUsers,
  loggedInUser,
  setAlert,
  functions,
}: {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  friends: CometChat.User[],
  members: CometChat.GroupMember[],
  setMembers: (members: CometChat.GroupMember[]) => void,
  currentChat: ActiveChannelInterface,
  blockedUsers: CometChat.User[],
  loggedInUser: CometChat.User | null,
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>,
  functions: Functions,
}) => {

  const [selectedMembers, setSelectedMembers] = useState<CometChat.GroupMember[]>([]);

  const friendsInGroup = friends.filter(friend => members.some(member => member.getUid() === friend.getUid()));
  const friendsNotInGroup = friends.filter(friend => !members.some(member => member.getUid() === friend.getUid()));

  const handleClose = () => {
    setSelectedMembers([]);
    setIsOpen(false);
  }

  const handleSelectFriend = (friend: CometChat.User) => {
    // If the friend is already selected, remove it from the selected members
    if (selectedMembers.some(member => member.getUid() === friend.getUid())) {
      setSelectedMembers(selectedMembers.filter(member => member.getUid() !== friend.getUid()));
      return;
    }
    // If the friend is not selected, add it to the selected members
    const member = new CometChat.GroupMember(friend.getUid(), CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT);
    member.setName(friend.getName());
    member.setAvatar(friend.getAvatar());
    member.setStatus(friend.getStatus());
    member.setMetadata(friend.getMetadata());
    setSelectedMembers([...selectedMembers, member]);
  };

  const handleOrderMembers = (members: CometChat.GroupMember[]) => {
    if (!loggedInUser) return members;
    // Sort members alphabetically and separate online/offline members
    const sortedMembers = members.sort((a, b) => a.getName().localeCompare(b.getName()));
    const onlineMembers = sortedMembers.filter(member => member.getStatus() === 'online');
    const offlineMembers = sortedMembers.filter(member => member.getStatus() === 'offline');

    const mergedMembers = [...onlineMembers, ...offlineMembers];

    // Move the logged in user to the top of the list
    const loggedInUserIndex = mergedMembers.findIndex(member => member.getUid() === loggedInUser.getUid());
    if (loggedInUserIndex !== -1) {
      const loggedInUser = mergedMembers.splice(loggedInUserIndex, 1);
      mergedMembers.unshift(loggedInUser[0]);
    }

    return mergedMembers;
  };

  const handleAddMembers = async () => {
    if (selectedMembers.length === 0) handleClose();
    if (!loggedInUser) return;

    try {
      await CometChat.addMembersToGroup(currentChat.id, selectedMembers, []);

      // Add the selected members to the group
      const updatedMembers = [...members, ...selectedMembers];
      const sortedMembers = handleOrderMembers(updatedMembers);
      setMembers(sortedMembers);

      const group = await CometChat.getGroup(currentChat.id);

      // Notify all users to reload their group list
      const uids = selectedMembers
        .filter(member => member.getUid() !== loggedInUser.getUid())
        .map(member => member.getUid());

      // Notify the users that were added to the group to reload their group list
      notifyMultipleUsersReload(functions, uids, 'group_created', group);

      setAlert({ message: 'Gebruikers succesvol toegevoegd', type: 'success' });
      // Close the modal
      handleClose();
    } catch (error) {
      console.error("Something went wrong with adding members", error);
      setAlert({ message: 'Er is iets misgegaan bij het toevoegen van gebruikers', type: 'error' });
    }
  };

  const checkBlocked = (user: CometChat.User) => {
    return checkIfUserIsBlocked(blockedUsers, user.getUid());
  }

  return (
    <Modal
      open={isOpen}
      header={
        <div className="addGroupModalHeaderContainer">
          <div className="step-one">
            <h1 className="addGroupModalHeaderTitle">Voeg gebruikers toe aan je groep</h1>
            <span className="addGroupModalHeaderSpan">{members.length}/50</span>
          </div>
        </div>
      }
      actions={
        <>
          <Button flatButton onClick={handleClose}>ANNULEER</Button>
          <Button
            flatButton
            onClick={handleAddMembers}
            disabled={selectedMembers.length === 0}
          >
            NEXT
          </Button>
        </>
      }
      headerStyle={{ fontSize: '16px', }}
    >
      <Divider />

      {friends.length === 0 ?
        <div className='no-friends'>Geen vrienden gevonden...</div> :
        <List style={{ height: '400px' }}>
          {/* Display friends already in the group with a checked checkbox */}
          {friendsInGroup.map((friend) => (
            <div key={friend.getUid()}>
              <ListItem
                primaryText={friend.getName()}
                avatar={friend.getAvatar() ?? defaultAvatar}
                blocked={checkBlocked(friend)}
                rightButton={
                  <Checkbox
                    checked={true}
                    onChange={() => { }}
                  />
                }
                style={{ opacity: '0.25' }}
              />
              <Divider />
            </div>
          ))}
          {friendsNotInGroup.map((friend) => (
            <div key={friend.getUid()}>
              <ListItem
                primaryText={friend.getName()}
                avatar={friend.getAvatar() ?? defaultAvatar}
                blocked={checkBlocked(friend)}
                rightButton={
                  <Checkbox
                    checked={selectedMembers.some(member => member.getUid() === friend.getUid())}
                    disabled={selectedMembers.length >= 50}
                    onChange={() => handleSelectFriend(friend)}
                  />
                }
              />
              <Divider />
            </div>
          ))}
        </List>
      }
    </Modal>
  )
}

export default AddUserToGroupModal;