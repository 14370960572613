import { CometChat } from '@cometchat-pro/chat';
import Profile from 'anchor-ui/profile';
import defaultAvatar from "./../../assets/default_avatar.jpg";
import Button from 'anchor-ui/button';
import Card from 'anchor-ui/card';
import CardHeader from 'anchor-ui/card-header';
import CardContent from 'anchor-ui/card-content';
import Divider from 'anchor-ui/divider';
import {
  IconConversation,
  IconAddFriend,
  IconRules,
  IconPerson,
  IconMaleFemale,
  IconHeart,
  IconClose
} from 'anchor-ui/icons';
import { getActiveColor } from './../../utils/activeColor';
import './userProfile.css';
import { metadataInterface } from './../../interfaces/metadataInterface';
import ProfileDetail from './ProfileDetail';
import ToggleLeftMenuButton from '../ToggleLeftMenuButton';
import { ActiveChannelInterface } from '../../interfaces/activeChannelInterface';

const UserProfile = ({
  user,
  setCurrentChat,
  startPrivateChat,
  friends,
  hasSentFriendRequest,
  hasReceivedFriendRequest,
  handleAddOrRemoveFriend,

  leftMenuOpen,
  setLeftMenuOpen,
  unreadCount,
}: {
  user: CometChat.User;
  setCurrentChat: (chat: any) => void;
  startPrivateChat: (user: CometChat.User) => void;
  friends: CometChat.User[];
  hasSentFriendRequest: (user: CometChat.User) => boolean;
  hasReceivedFriendRequest: (user: CometChat.User) => boolean;
  handleAddOrRemoveFriend: (friend: CometChat.User) => void;

  leftMenuOpen: boolean;
  setLeftMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
}) => {

  const getProfileFromMetadata = (user: CometChat.User) => {
    const metadata = user.getMetadata() as unknown as metadataInterface;

    if (!metadata || typeof metadata === 'string') {
      return {
        about: {
          gender: 'unknown',
          birthDate: '01-01-1970',
        }
      }
    }

    return metadata;
  }

  const userProfile: metadataInterface = getProfileFromMetadata(user);
  const isPrivate = userProfile.settings?.profileIsPrivate;

  const closeProfile = () => {
    setCurrentChat((prevState: any) => {
      return {
        ...prevState,
        userProfile: null,
      }
    });
  };

  const userIsFriend = friends.some(friend => friend.getUid() === user.getUid());

  return (
    <Profile
      header={user.getName()}
      avatar={user.getAvatar() ?? defaultAvatar}
      coverImage={user.getAvatar() ?? defaultAvatar}
      coverImageStyle={{ backgroundColor: getActiveColor() }}
      status={user.getStatus()}
      style={{
        width: '100%',
        backgroundColor: 'rgb(246, 246, 246)',
      }}
      avatarStyle={{
        width: '156px',
        height: '156px',
        left: '102px',
      }}
      headerStyle={{
        width: 'auto',
        position: 'absolute',
        textAlign: 'left',
        left: '196px',
        top: '200px'
      }}
      secondaryTextStyle={{
        width: 'auto',
        position: 'absolute',
        textAlign: 'left',
        left: '196px',
        top: '254px'
      }}
      button={
        <>
          <ToggleLeftMenuButton
            leftMenuOpen={leftMenuOpen}
            setLeftMenuOpen={setLeftMenuOpen}
            unreadCount={unreadCount}
            isProfile
          />

          <Button
            iconButton
            style={{
              fontSize: '16px',
              position: 'absolute',
              top: '8px',
              right: '8px',
              backgroundColor: 'rgba(21, 21, 21, 0.2)'
            }}
            onClick={closeProfile}
          >
            <IconClose color='white' />
          </Button>

          <div className="profile-buttons">
            <Button
              flatButton
              style={{
                color: 'rgb(196, 196, 196)',
                fontSize: '16px',
                fontWeight: 'bolder',
                display: 'flex',
                alignItems: 'center',
                textTransform: 'none',
              }}
              onClick={() => startPrivateChat(user)}
            >
              <IconConversation />
              <span style={{ marginLeft: '8px' }}>Open gesprek</span>
            </Button>
            {(!hasSentFriendRequest(user) && !hasReceivedFriendRequest(user)) &&
              <Button
                flatButton
                style={{
                  color: 'rgb(196, 196, 196)',
                  fontSize: '16px',
                  fontWeight: 'bolder',
                  display: 'flex',
                  alignItems: 'center',
                  textTransform: 'none'
                }}
                onClick={() => handleAddOrRemoveFriend(user)}
              >
                <IconAddFriend />
                <span style={{ marginLeft: '8px' }}>
                  {userIsFriend ? 'Vriend verwijderen' : 'Vriend toevoegen'}
                </span>
              </Button>
            }
          </div>
        </>
      }
    >
      <div className="profile-card-container profile-card-section">
        <Card style={{ backgroundColor: 'white' }}>
          {isPrivate ? <CardHeader title={'Dit profiel is privé.'} /> :
            <>
              <CardHeader
                title={
                  <div className="profile-card-header">
                    <span
                      className="profile-card-icon"
                      style={{
                        backgroundColor: getActiveColor()
                      }}
                    >
                      <IconRules color="white" />
                    </span>
                    <span className="profile-card-title">Over</span>
                  </div>
                }
                style={{ paddingBottom: 0 }}
              />
              <CardContent style={{ paddingTop: '8px' }}>

                {userProfile.about.aboutText &&
                  <>
                    <span>
                      {userProfile.about.aboutText}
                    </span>
                    <Divider style={{ marginTop: '12px', marginBottom: '8px' }} />
                  </>
                }

                <div className="profile-detail">
                  <IconMaleFemale />
                  <p style={{ margin: 0, marginLeft: '8px' }}>{userProfile.about.gender}</p>
                </div>

                {userProfile.about.relationshipStatus &&
                  <div className="profile-detail">
                    <IconHeart />
                    <p style={{ margin: 0, marginLeft: '8px' }}>{userProfile.about.relationshipStatus}</p>
                  </div>
                }

              </CardContent>
            </>
          }
        </Card>

        {userProfile.appearance && !isPrivate &&
          <Card style={{ marginTop: '8px', backgroundColor: 'white' }}>
            <CardHeader
              title={
                <div className="profile-card-header">
                  <span
                    className="profile-card-icon"
                    style={{
                      backgroundColor: getActiveColor()
                    }}
                  >
                    <IconPerson color="white" />
                  </span>
                  <span className="profile-card-title">Uiterlijk</span>
                </div>
              }
              style={{ paddingBottom: 0 }}
            />
            <CardContent style={{ paddingTop: '8px' }}>

              <ProfileDetail label="Bouw" value={userProfile.appearance.build} />
              <ProfileDetail label="Kleur haar" value={userProfile.appearance.hairColor} />
              <ProfileDetail label="Kleur ogen" value={userProfile.appearance.eyeColor} />
              <ProfileDetail label="Piercings" value={userProfile.appearance.piercings} />
              <ProfileDetail label="Tattoos" value={userProfile.appearance.tattoos} />

            </CardContent>
          </Card>
        }
      </div>

    </Profile>
  );
}

export default UserProfile;