import Layout from "../Layout";
import './styles.css';
import contactImg from '../../assets/contact.avif';

const ContactPage = () => {
  return (
    <Layout heroText="Contact" heroImg={contactImg}>

      <div className="page-container">
        <div className='container-content'>
          <div className="contact-content">
            <h1>Contacteer ons</h1>
            <p>Welkom op de contactpagina van Chatplaza.com! Heb je vragen, opmerkingen of suggesties? Wij staan voor je klaar en helpen je graag verder. Hieronder vind je alle manieren om contact met ons op te nemen.</p>

            <p>
              <b>Email:</b> <a href="mailto:contact@email.com" className="text-blue">contact@email.com</a>
            </p>

            <p>
              KVK 12345678<br />
              BTW NL123456789B01
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ContactPage;
