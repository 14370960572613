import Layout from "../Layout";
import blogImg from '../../assets/blog.avif';
import './styles.css';
import blogData from './Blogs/articles.json';

const BlogPage = () => {
  return (
    <Layout heroText="Blog" heroImg={blogImg}>
      <div className="page-container">
        <div className='container-content'>
          <div className="blog-picker">

            {blogData.map((blog, index) => (
              <a className="blog-card" key={index} href={`/blog/${blog.url}`}>
                <img src={blogImg} alt="Blog" />
                <div className="blog-card-content">
                  <h3>{blog.title}</h3>
                  <p>
                    {blog.content.intro.length > 100 ? blog.content.intro.substring(0, 100) + '...' : blog.content.intro}
                  </p>
                  <small>{blog.publicationDate}</small>
                </div>
              </a>
            ))}

          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BlogPage;