import React, { useState } from 'react';
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import { IconClose } from 'anchor-ui/icons';
import defaultAvatar from "./../../../assets/default_avatar.jpg";
import privateChannelImage from "./../../../assets/private_channel.png";
import AddGroupModal from './AddGroupModal';
import { getActiveColor } from '../../../utils/activeColor';
import Avatar from 'anchor-ui/avatar';
import RemoveConversationModal from './RemoveConversationModal';

const ConversationsListComponent = ({
  conversations,
  unreadCount,
  handleConversationClick,
  handleGroupConversationClick,
  initialConversation,
  currentChat,
  setCurrentChat,
  friends,
  refreshConversations,
  lastConversationMsg,
  setUnreadCount,
  loggedInUser,
  blockedUsers,
  setAlert,
  isTimeline,
  functions,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRemoveConversationModalOpen, setIsRemoveConversationModalOpen] = useState(false);
  const [removeConversation, setRemoveConversation] = useState(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const RemoveConversationButton = ({ conversation }) => {

    const handleOpenRemoveConversationModal = (conversation, event) => {
      event.stopPropagation();

      setRemoveConversation(conversation);
      setIsRemoveConversationModalOpen(true);
    };

    return (
      <div data-radium="true" style={{ position: 'relative' }}>
        <div>
          <button
            type="button"
            data-radium="true"
            className='li-button'
            onClick={(event) => handleOpenRemoveConversationModal(conversation, event)}
          >
            <IconClose />
          </button>
        </div>
      </div>
    );
  };

  const getUserAvatar = (conversation) => {
    return conversation.conversationWith.avatar ?? defaultAvatar;
  };

  const getLastMessageText = (conversation) => { // --> conversation.lastMessage is not up-to-date
    if (!conversation.lastMessage) return '';

    const isSender = conversation.lastMessage.rawMessage.sender === loggedInUser.uid;
    const prefix = isSender && !conversation.lastMessage.message ? 'jij: ' : '';

    const getMessageText = (conversation, lastConversationMsg) => {
      if (lastConversationMsg && lastConversationMsg.conversationId === conversation.conversationId) {
        return lastConversationMsg.message;
      }

      const { type, text, message } = conversation.lastMessage;
      const isEmpty = (str) => !str || str.trim() === '';

      switch (type) {
        case 'image':
          return 'Afbeelding';
        case 'extension_poll':
          return 'Poll';
        default:
          return !isEmpty(text) ? text : !isEmpty(message) ? message : '';
      }
    };

    const message = getMessageText(conversation, lastConversationMsg);

    if (message !== '') return prefix + message;
    return message;

  };

  const formatDate = (timestamp) => {
    const daysOfWeek = ["zo.", "ma.", "di.", "wo.", "do.", "vr.", "za."];
    const date = new Date(timestamp * 1000); // Convert to milliseconds
    const now = new Date();

    // Helper to zero-pad day or month numbers
    const padZero = (num) => num.toString().padStart(2, '0');

    // Check if date is today
    if (
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear()
    ) {
      const hours = padZero(date.getHours());
      const minutes = padZero(date.getMinutes());
      return `${hours}:${minutes}`;
    }

    // Check if date is yesterday
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    if (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    ) {
      return "gisteren";
    }

    // Check if date is within the same week
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - now.getDay()); // Set to start of the week (Sunday)

    if (date >= startOfWeek) {
      return daysOfWeek[date.getDay()];
    }

    // Otherwise, return date as dd-mm-yyyy
    const day = padZero(date.getDate());
    const month = padZero(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const getPrimaryText = (text) => {
    return text.length > 8 ? text.substring(0, 8) + '...' : text;
  }

  const initialLastMessageText = 'Deel nooit gegevens met vreemden en open nooit onbekende urls. ChatPlaza en moderators zullen nooit vragen naar accountgegevens of vragen om betalingen te doen. Kom je iets verdachts tegen? Rapporteer het bericht en/of de gebruiker.';

  const displayedConversations = isTimeline ? conversations.slice(0, 5) : conversations;

  return (
    <div>
      <List header={`${isTimeline ? 'Recente gesprekken' : 'Gesprekken'}`}>
        <React.Fragment key={'gesprekken'}>
          <AddGroupModal
            isOpen={isModalOpen}
            closeModal={closeModal}
            friends={friends}
            refreshConversations={refreshConversations}
            setAlert={setAlert}
            functions={functions}
          />
          <RemoveConversationModal
            isOpen={isRemoveConversationModalOpen}
            closeModal={() => setIsRemoveConversationModalOpen(false)}
            conversation={removeConversation}
            refreshConversations={refreshConversations}
            currentChat={currentChat}
            setCurrentChat={setCurrentChat}
            initialConversation={initialConversation}
            setUnreadCount={setUnreadCount}
            loggedInUser={loggedInUser}
            setAlert={setAlert}
            functions={functions}
          />

          {!isTimeline &&
            <ListItem
              primaryText={'Nieuwe groep'}
              avatar={
                <Avatar
                  image={privateChannelImage}
                  style={{ backgroundColor: getActiveColor() }}
                />
              }
              onClick={openModal}
            />
          }

          {initialConversation &&
            <ListItem
              primaryText={getPrimaryText(initialConversation.conversationWith.name)}
              secondaryText={initialLastMessageText}
              avatar={
                <Avatar
                  image={getUserAvatar(initialConversation)}
                  style={{ backgroundColor: getActiveColor() }}
                  status={initialConversation.conversationWith.status}
                />
              }
              onClick={() => handleConversationClick(initialConversation)}
              rightButton={<RemoveConversationButton conversation={initialConversation} />}
              blocked={blockedUsers.includes(initialConversation.conversationWith.uid)}
            />
          }

          {displayedConversations.map((conversation, index) => {
            const isGroup = conversation.isGroup || conversation.conversationType === 'group' || 'guid' in conversation;

            let unreadMessages;
            if (isGroup && unreadCount[conversation.guid] !== undefined) {
              // Get the unread messages count for the groupconversation
              unreadMessages = unreadCount[conversation.guid] ? unreadCount[conversation.guid].nr : 0;
            } else {
              // Get the unread messages count for the private conversation
              unreadMessages = unreadCount[conversation.conversationId] ? unreadCount[conversation.conversationId].nr : 0;
            }

            return (
              <span key={index}>
                {conversation &&
                  <ListItem
                    primaryText={
                      <span data-radium="true" className="conversationlist-container">
                        {getPrimaryText(isGroup ? conversation.name : conversation.conversationWith.name)}
                        <span data-radium="true" className="conversationlist-date">
                          {isGroup ? formatDate(conversation.lastMessage?.sentAt) : formatDate(conversation.getLastMessage().getSentAt())}
                        </span>
                      </span>
                    }
                    secondaryText={getLastMessageText(conversation)}
                    avatar={
                      <Avatar
                        image={isGroup ? privateChannelImage : getUserAvatar(conversation)}
                        style={{ backgroundColor: getActiveColor() }}
                        status={!isGroup && conversation.conversationWith.status}
                      />
                    }
                    onClick={() => isGroup ? handleGroupConversationClick(conversation) : handleConversationClick(conversation)}
                    rightButton={<RemoveConversationButton conversation={conversation} />}
                    badge={unreadMessages &&
                      <div className='badge-unread'>
                        {unreadMessages}
                      </div>
                    }
                    blocked={!isGroup && blockedUsers.includes(conversation.conversationWith.uid)}
                  />
                }
              </span>
            )
          })}
        </React.Fragment>
      </List>
    </div >
  );
};

export default ConversationsListComponent;