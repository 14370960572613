import { CometChat } from '@cometchat-pro/chat';
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import defaultAvatar from "./../../assets/default_avatar.jpg";
import { IconMore } from 'anchor-ui/icons';
import MenuItem from 'anchor-ui/menu-item';
import {
  IconConversation,
  IconPerson,
  IconAddFriend,
  IconBlock,
  IconReport,
  IconThumbsDown,
  IconHammer,
} from 'anchor-ui/icons';
import Avatar from 'anchor-ui/avatar';
import { getActiveColor } from './../../utils/activeColor';
import IconMenu from 'anchor-ui/icon-menu';
import Divider from 'anchor-ui/divider';

import { ActiveChannelInterface } from './../../interfaces/activeChannelInterface';
import privateChannelImage from "./../../assets/private_channel.png";
import AddUserToGroupModal from './AddUserToGroupModal';
import { useEffect, useState } from 'react';
import { getGenderFromMetadata } from './../../utils/getGenderFromMetadata';
import { checkIfUserIsBlocked } from '../../utils/checkIfUserIsBlocked';
import { getProfileFromMetadata } from '../../utils/updateMetadata';
import { Functions } from 'firebase/functions';

const GroupListComponent = ({
  members,
  setMembers,
  loggedInUser,
  friends,
  blockedUsers,
  handleBlockUser,
  hasSentFriendRequest,
  hasReceivedFriendRequest,
  handleAddOrRemoveFriend,
  handleBanUser,
  handleKickUser,
  // handleMakeUserAdmin,
  currentChat,
  setCurrentChat,
  startPrivateChat,
  setUserToReport,
  setAlert,
  functions,
}: {
  members: CometChat.GroupMember[];
  setMembers: (members: CometChat.GroupMember[]) => void;
  loggedInUser: CometChat.User | null;
  friends: CometChat.User[];
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  hasSentFriendRequest: (user: CometChat.User) => boolean;
  hasReceivedFriendRequest: (user: CometChat.User) => boolean;
  handleAddOrRemoveFriend: (friend: CometChat.User) => void;
  handleBanUser: (user: CometChat.User) => void;
  handleKickUser: (user: CometChat.User, group: CometChat.Group) => void;
  // handleMakeUserAdmin: (user: CometChat.User) => void;
  currentChat: ActiveChannelInterface;
  setCurrentChat: (chat: any) => void;
  startPrivateChat: (user: CometChat.User) => void;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  functions: Functions;
}) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [admins, setAdmins] = useState<CometChat.User[]>([]);
  const [regularMembers, setRegularMembers] = useState<CometChat.User[]>(members);

  const checkIfAdmin = (user: CometChat.User) => {
    return user.getRole() === 'admin';
  }

  useEffect(() => {
    setAdmins(members.filter(member => checkIfAdmin(member)));
    setRegularMembers(members.filter(member => !checkIfAdmin(member)));
  }, [members]);

  const isFriend = (user: CometChat.User) => {
    return friends.some(friend => friend.getUid() === user.getUid());
  };

  const isPublicProfile = (user: CometChat.User) => {
    const profile = getProfileFromMetadata(user);
    return !profile.settings?.profileIsPrivate;
  }

  const showUserProfile = (user: CometChat.User) => {
    setCurrentChat((prev: any) => ({ ...prev, userProfile: user }));
  }

  const checkBlocked = (user: CometChat.User) => {
    return checkIfUserIsBlocked(blockedUsers, user.getUid());
  }

  const GroupMemberListItem = ({
    key,
    member,
    isAdmin,
  }: {
    key: number,
    member: CometChat.User,
    isAdmin?: boolean,
  }) => {

    return (
      <ListItem
        key={key}
        primaryText={member.getName()}
        avatar={
          <Avatar
            image={member.getAvatar() ? member.getAvatar() : defaultAvatar}
            status={member.getStatus()}
          />
        }
        textBadge={
          <>
            {isAdmin &&
              <span className='text-badge' style={{ backgroundColor: getActiveColor() }}>
                Admin
              </span>
            }
            {isFriend(member) &&
              <span className='text-badge' style={{ backgroundColor: getActiveColor() }}>
                Vriend
              </span>
            }
          </>
        }
        blocked={checkBlocked(member)}
        secondaryText={getGenderFromMetadata(member)}
        rightButton={
          !loggedInUser ? <></> :
            member.getUid() === loggedInUser.getUid() ? null :
              <IconMenu icon={<IconMore />}>
                <MenuItem
                  text="Start privé kanaal"
                  icon={<IconConversation />}
                  onClick={() => startPrivateChat(member)}
                />

                {isPublicProfile(member) &&
                  <MenuItem
                    text="Bekijk profiel"
                    icon={<IconPerson />}
                    onClick={() => showUserProfile(member)}
                  />
                }

                {!hasSentFriendRequest(member) &&
                  <MenuItem
                    text={friends.some(friend => friend.getUid() === member.getUid()) ?
                      "Verwijder Vriend" :
                      hasReceivedFriendRequest(member) ?
                        "Accepteer vriendschapsverzoek" :
                        "Stuur een vriendschapsverzoek"
                    }
                    icon={<IconAddFriend />}
                    onClick={() => handleAddOrRemoveFriend(member)}
                  />
                }

                <MenuItem
                  text={checkBlocked(member) ?
                    "Deblokkeer gebruiker" :
                    "Blokkeer gebruiker"
                  }
                  icon={<IconBlock />}
                  onClick={() => handleBlockUser(member.getUid())}
                />

                <MenuItem
                  text="Rapporteer gebruiker"
                  icon={<IconReport />}
                  onClick={() => setUserToReport({ user: member, guid: currentChat.id })}
                />

                {!isAdmin && (
                  <>
                    <Divider />
                    <MenuItem
                      text="Kick gebruiker"
                      icon={<IconThumbsDown color={getActiveColor()} />}
                      onClick={() => handleKickUser(member, new CometChat.Group(currentChat.id, currentChat.name))}
                    />

                    <MenuItem
                      text="Ban gebruiker"
                      icon={<IconHammer color={getActiveColor()} />}
                      onClick={() => handleBanUser(member)}
                    />
                  </>
                )}
              </IconMenu>
        }
      />
    );
  }

  return (
    <>
      <AddUserToGroupModal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        friends={friends}
        members={members}
        setMembers={setMembers}
        currentChat={currentChat}
        blockedUsers={blockedUsers}
        loggedInUser={loggedInUser}
        setAlert={setAlert}
        functions={functions}
      />

      {/* Admin */}
      {admins.length > 0 && (
        <List header={!currentChat.isOwner && 'Admins'}>
          {currentChat.isOwner &&
            <ListItem
              primaryText='Voeg leden toe'
              avatar={
                <Avatar
                  image={privateChannelImage}
                  style={{ backgroundColor: getActiveColor() }}
                />
              }
              onClick={() => setModalIsOpen(true)}
            />
          }
          {admins.map((admin, index) => (
            <GroupMemberListItem key={index} member={admin} isAdmin={true} />
          ))}
        </List>
      )}

      {/* Regular members */}
      {regularMembers.length > 0 && (
        <List header={(!currentChat.isOwner || admins.length > 0) && 'Gebruikers'}>
          {currentChat.isOwner && admins.length === 0 &&
            <ListItem
              primaryText='Voeg leden toe'
              avatar={
                <Avatar
                  image={privateChannelImage}
                  style={{ backgroundColor: getActiveColor() }}
                />
              }
              onClick={() => setModalIsOpen(true)}
            />
          }
          {regularMembers.map((member, index) => (
            <GroupMemberListItem key={index} member={member} />
          ))}
        </List>
      )}
    </>
  );
};

export default GroupListComponent;
