import Switch from 'anchor-ui/switch';

const PrivacyPage = ({
  isPublic,
  changeVisiblity,
}: {
  isPublic: boolean;
  changeVisiblity: (isPublic: boolean) => void;
}) => {
  
  return (
    <div>
      <h2>Privacy</h2>

      <Switch
        label='Publiek profiel'
        active={isPublic}
        toggleSwitch={changeVisiblity}
      />

      <p>Andere gebruikers kunnen jouw profiel {!isPublic && 'niet '}bekijken op www.chatplaza.com.</p>

    </div>
  );
}

export default PrivacyPage;