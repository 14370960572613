import { CometChat } from "@cometchat-pro/chat";

export const fetchFriendsList = async (limit = 30) => {
  try {
    let usersRequest = new CometChat.UsersRequestBuilder()
      .setLimit(limit)
      .friendsOnly(true)
      .build();

    const userList = await usersRequest.fetchNext();
    return userList as CometChat.User[];
  } catch (error) {
    console.error("Error fetching friends list:", error);
    throw error;
  }
}