import Profile from 'anchor-ui/profile';
import defaultAvatar from "./../../assets/default_avatar.jpg";
import Button from 'anchor-ui/button';
import { CometChat } from '@cometchat-pro/chat';
import { FriendRequest } from './../../utils/class/FriendRequest';
import { getGenderFromMetadata } from './../../utils/getGenderFromMetadata';
import { getActiveColor } from './../../utils/activeColor';

const UserProfileConvComponent = ({
  user,
  friends,
  handleFriendRequest,
  incomingFriendRequests,
  handleAcceptFriendRequest,
  outgoingFriendRequests
}: {
  user: CometChat.User | undefined,
  friends: CometChat.User[],
  handleFriendRequest: (user: CometChat.User) => void,
  incomingFriendRequests: FriendRequest[],
  handleAcceptFriendRequest: (request: FriendRequest) => void
  outgoingFriendRequests: CometChat.User[],
  }) => {

  const isFriendOrRequestSent = () => {
    if (!user) return null;
    return friends.some(friend => friend.getUid() === user.getUid()) || outgoingFriendRequests.some(request => request.getUid() === user.getUid());
  }

  if (!user) {
    return null;  // or return a loading spinner, or some placeholder content
  }

  let avatar = defaultAvatar;
  if (user.getAvatar() !== undefined) {
    avatar = user.getAvatar();
  }

  const hasReceivedFriendRequest = (user: CometChat.User) => {
    return incomingFriendRequests.some(friendRequest => friendRequest.getUid() === user.getUid());
  };

  const handleAcceptBtnClick = (user: CometChat.User) => {
    const friendRequest = incomingFriendRequests.find(friendRequest => friendRequest.getUid() === user.getUid());
    if (!friendRequest) return;

    handleAcceptFriendRequest(friendRequest);
  }

  return (
    <div className='conversation-profile'>
      <Profile
        coverImage={avatar}
        avatar={avatar}
        header={user.getName()}
        secondaryText={getGenderFromMetadata(user, true)}
        status={user.getStatus()}
        coverImageStyle={{ backgroundColor: getActiveColor()}}
        button={
          !isFriendOrRequestSent() &&
          <>
            {hasReceivedFriendRequest(user) ?
              <Button onClick={() => handleAcceptBtnClick(user)}>
                Accepteer vriendschapsverzoek
              </Button> :
              <Button onClick={() => handleFriendRequest(user)}>
                Stuur Een Vriendschapsverzoek
              </Button>
            }
          </>
        }
      />
    </div>
  );
}

export default UserProfileConvComponent;