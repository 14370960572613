import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { applyActionCode, Auth } from 'firebase/auth';
import ChatPlazaLogo from '../../assets/chatplaza-logo.svg';
import CardHeader from 'anchor-ui/card-header';

import Card from 'anchor-ui/card';
import Button from 'anchor-ui/button';

const VerifyEmailComponent = ({ auth, oobCode, email }: { auth: Auth, oobCode: string; email: string | null }) => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!oobCode || success) return;

    applyActionCode(auth, oobCode).then(() => {
      setSuccess(true);
      setLoading(false);
    }).catch(() => {
      setError('Ongeldige of verlopen verificatie code');
      setLoading(false);
    });
  }, [oobCode, auth, success]);

  return (
    <section className="action-page">
      <Card className="action-card">
        <img src={ChatPlazaLogo} className="logo" />
        <CardHeader title="Verifieer Email" style={{ alignSelf: "center" }} />
        {loading ? (
          <p>Verifiëren...</p>
        ) : success ? (
          <>
            <p>Email {email} succesvol geverifieerd!</p>
            <Button
              inverted
              onClick={() => navigate('/login')}
              style={{ width: '100%' }}
            >
              Naar login
            </Button>
          </>
        ) : (
          <>
            <p>Verifieer email {email}</p>
            {error && <p className="action-error-message">{error}</p>}
          </>
        )}
      </Card>
    </section>

  );
};

export default VerifyEmailComponent;