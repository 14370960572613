import Dialog from 'anchor-ui/dialog';
import Button from 'anchor-ui/button';
import { useEffect, useState } from 'react';
import Input from 'anchor-ui/input';

// Import Firebase modules
import { Auth, sendPasswordResetEmail } from 'firebase/auth';

const PasswordResetDialog = ({
  isOpen,
  setIsOpen,
  auth,
  email,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  auth: Auth;
  email: string;
}) => {
  const [emailTo, setEmailTo] = useState(email);
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [emailSent, setEmailSent] = useState('');

  useEffect(() => {
    setEmailTo(email);
    setEmailSent('');
  }, [email]);

  const handleClose = () => {
    setIsOpen(false);
    setError(null); // Clear any existing error
  };

  const handleSendResetEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSending(true);
    setError(null);

    try {
      // await sendPasswordResetEmail(auth, emailTo);
      await sendPasswordResetEmail(auth, emailTo, {
        url: `${window.location.origin}/reset-password`,
      });
      setEmailSent(emailTo);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError('Er is een fout opgetreden bij het verzenden van de wachtwoord reset email.');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      header='Wachtwoord reset email'
      hideDialog={handleClose}
      headerStyle={{ color: 'rgb(117, 117, 117)', fontSize: '24px', fontWeight: 'bolder', textAlign: 'center' }}
      style={{ backgroundColor: 'white' }}
      iconColor={'rgba(117, 117, 117, 0.5)'}
    >
      <form onSubmit={handleSendResetEmail}>
        <Input
          label='Email'
          type='email'
          value={emailTo}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailTo(e.target.value)}
          style={{ maxWidth: '100%' }}
          maxLength={50}
          required
        />

        {error && <p style={{ color: 'red', marginBottom: '10px' }}>{error}</p>}
        {emailSent && <p style={{ color: 'green', marginBottom: '10px' }}>Een email met een wachtwoord reset-link is verstuurd naar {emailSent}</p>}

        <Button
          type="submit"
          style={{ display: 'block', margin: '16px auto 0 auto' }}
          disabled={!emailTo || isSending}
        >
          {isSending ? 'Versturen...' : emailSent ? 'Stuur opnieuw' : 'Stuur reset email'}
        </Button>
      </form>
    </Dialog>
  );
};

export default PasswordResetDialog;
