import { CometChat } from '@cometchat-pro/chat';

export class GroupConversation extends CometChat.Group {
  lastMessage: CometChat.BaseMessage;

  constructor(group: CometChat.Group, lastMessage: CometChat.BaseMessage) {
    super(
      group.getGuid(),
      group.getName(),
      group.getType(),
      group.getPassword(),
      group.getIcon(),
      group.getDescription(),
    );
    this.setOwner(group.getOwner());
    this.lastMessage = lastMessage;
  }
}