import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getAuth, checkActionCode, verifyPasswordResetCode } from 'firebase/auth';
import VerifyEmailComponent from './VerifyEmailComponent';
import ResetPasswordComponent from './ResetPasswordComponent';

const ActionPage = () => {
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get('oobCode');
  const auth = getAuth();

  const [actionType, setActionType] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [email, setEmail] = useState<string | null>(null);

  useEffect(() => {
    if (!oobCode) {
      setError('Ongeldige of verlopen actiecode');
      setLoading(false);
      return;
    }

    checkActionCode(auth, oobCode).then((info) => {
      setActionType(info.operation);
      setEmail(info.data.email || '');
      setLoading(false);
    }).catch(() => {
      setError('Ongeldige of verlopen actiecode');
      setLoading(false);
    });

    if (actionType === 'PASSWORD_RESET') {
      verifyPasswordResetCode(auth, oobCode).then((email) => {
        setEmail(email);
      }).catch(() => {
        setError('Ongeldige of verlopen wachtwoord reset code');
      });
    }
  }, [oobCode, auth, actionType]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (actionType === 'PASSWORD_RESET' && oobCode) {
    return <ResetPasswordComponent
      auth={auth}
      oobCode={oobCode}
      email={email}
    />;
  }

  if (actionType === 'VERIFY_EMAIL' && oobCode) {
    return <VerifyEmailComponent
      auth={auth}
      oobCode={oobCode}
      email={email}
    />;
  }

  return <p>Ongeldige actie</p>;
};

export default ActionPage;