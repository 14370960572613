import './styles.css';
import Header from "../Header";
import ConversationSDK from "../ConversationSDK";
import { useEffect } from "react";
import { ActiveChannelInterface } from "../../interfaces/activeChannelInterface";
import { CometChat } from '@cometchat-pro/chat';
import {
  acceptFriendRequest,
  removeFriend,
  sendFriendRequest
} from "../ConversationSDK/friendRequests";
import { FriendRequest } from "../../utils/class/FriendRequest";
import { useNavigate } from "react-router-dom";
import { GroupConversation } from "../../utils/class/GroupConversation";
import { NotificationInterface } from "../../interfaces/notificationInterface";
import { User } from "firebase/auth";
import { getProfileFromMetadata } from '../../utils/updateMetadata';
import { Functions, httpsCallable } from 'firebase/functions';
import LoadingPage from './LoadingPage';

export const Chat = ({
  loggedInUser,
  isLoading,

  unreadCount,
  setUnreadCount,
  conversations,
  setConversations,
  initialConversation,
  setInitialConversation,
  loadConversations,
  activeTabIndex,
  setActiveTabIndex,
  notifications,
  setNotifications,
  channels,
  loadIncomingFriendRequests,
  incomingFriendRequests,
  loadOutgoingFriendRequests,
  outgoingFriendRequests,
  blockedUsers,
  fetchBlockedUsers,
  friends,
  loadFriends,
  setFriends,
  handleLogout,

  currentChat,
  setCurrentChat,
  chatWithUser,
  setChatWithUser,
  messages,
  setMessages,
  members,
  setMembers,

  setAlert,
  activeColorState,
  setActiveColorState,
  messageFontSize,
  setMessageFontSize,
  generalFontSize,
  setGeneralFontSize,

  showLoadMostRecentMsgBtn,
  setShowLoadMostRecentMsgBtn,
  functions,

  handleChangeGroupConversation,

  activeChannels,
  setActiveChannels,

  handleChangeChannel,
}: {
  loggedInUser: { firebaseUser: User | null, cometChatUser: CometChat.User | null } | null,
  isLoading: boolean;

  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  setUnreadCount: React.Dispatch<React.SetStateAction<{ [id: string]: { nr: number, chat: ActiveChannelInterface } }>>;
  conversations: CometChat.Conversation[] | GroupConversation[];
  setConversations: React.Dispatch<React.SetStateAction<CometChat.Conversation[] | GroupConversation[]>>;
  initialConversation: CometChat.Conversation | null;
  setInitialConversation: React.Dispatch<React.SetStateAction<CometChat.Conversation | null>>;
  loadConversations: (user: CometChat.User) => void;
  activeTabIndex: number;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  notifications: NotificationInterface[] | null;
  setNotifications: React.Dispatch<React.SetStateAction<NotificationInterface[] | null>>;
  channels: CometChat.Group[];
  loadIncomingFriendRequests: () => void;
  incomingFriendRequests: FriendRequest[];
  loadOutgoingFriendRequests: () => void;
  outgoingFriendRequests: FriendRequest[];
  blockedUsers: CometChat.User[];
  fetchBlockedUsers: () => void;
  friends: CometChat.User[];
  loadFriends: () => void;
  setFriends: React.Dispatch<React.SetStateAction<CometChat.User[]>>;
  handleLogout: () => void;

  currentChat: ActiveChannelInterface;
  setCurrentChat: React.Dispatch<React.SetStateAction<ActiveChannelInterface>>;
  chatWithUser: CometChat.User | undefined;
  setChatWithUser: React.Dispatch<React.SetStateAction<CometChat.User | undefined>>;
  messages: CometChat.BaseMessage[];
  setMessages: React.Dispatch<React.SetStateAction<CometChat.BaseMessage[]>>;
  members: CometChat.GroupMember[];
  setMembers: React.Dispatch<React.SetStateAction<CometChat.GroupMember[]>>;

  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string } | null>>;
  activeColorState: string;
  setActiveColorState: React.Dispatch<React.SetStateAction<string>>;
  messageFontSize: string;
  setMessageFontSize: React.Dispatch<React.SetStateAction<string>>;
  generalFontSize: string;
  setGeneralFontSize: React.Dispatch<React.SetStateAction<string>>;

  showLoadMostRecentMsgBtn: boolean;
  setShowLoadMostRecentMsgBtn: React.Dispatch<React.SetStateAction<boolean>>;
  functions: Functions;

  handleChangeGroupConversation: (group: CometChat.Group) => Promise<void>;

  activeChannels: ActiveChannelInterface[];
  setActiveChannels: React.Dispatch<React.SetStateAction<ActiveChannelInterface[]>>;

  handleChangeChannel: (channel: ActiveChannelInterface) => void;
}) => {
  const loggedInCometChatUser = loggedInUser?.cometChatUser;

  const navigate = useNavigate();

  const handleChangeConversation = (conversation: CometChat.Conversation) => {
    const conversationWith = conversation.getConversationWith();
    const isGroup = conversation.getConversationType() === 'group';

    let receiverId;
    let guid;
    if (!isGroup && 'getUid' in conversationWith) {
      receiverId = conversationWith.getUid();
    } else if (isGroup && 'getGuid' in conversationWith) {
      guid = conversationWith.getGuid();
    }

    const newConversation = {
      id: guid ? guid : conversation.getConversationId(),
      receiverId: receiverId,
      icon: '',
      name: conversationWith.getName(),
      joinedAt: Date.now(),
      isGroup: isGroup
    };

    setCurrentChat(newConversation);
    const lastMsg = conversation.getLastMessage();

    try {
      CometChat.markAsRead(
        lastMsg.getId(),
        lastMsg.getSender().getUid(),
        'user',
        lastMsg.getSender().getUid()
      ).then(
        () => {
          conversation.setUnreadMessageCount(0);
          const { [conversation.getConversationId()]: _, ...rest } = unreadCount;

          setUnreadCount(rest);

          setActiveTabIndex(1);
        },
        (error: any) => {
          console.error('Messages marking as read failed with error:', error);
        }
      );
    } catch (error) {
      console.error('Error processing last message:', error);
    }
  };

  const refreshConversations = () => {
    if (!loggedInCometChatUser) return;
    loadConversations(loggedInCometChatUser);
  };

  const handleBlockUser = async (uid: string) => {
    try {
      const isBlocked = blockedUsers.some(user => user.getUid() === uid);

      if (isBlocked) {
        // Unblock user
        await CometChat.unblockUsers([uid]);
        setAlert({ message: 'Gebruiker gedeblokkeerd', type: 'success' });
      } else {
        // Block user
        await CometChat.blockUsers([uid]);
        setAlert({ message: 'Gebruiker geblokkeerd', type: 'success' });
      }
      // Fetch the updated list of blocked users
      fetchBlockedUsers();
    } catch (error) {
      console.error(`Error blocking/unblocking user ${uid}:`, error);
    }
  };

  const hasSentFriendRequest = (user: CometChat.User) => {
    return outgoingFriendRequests.some(friendRequest => friendRequest.getUid() === user.getUid());
  };

  const hasReceivedFriendRequest = (user: CometChat.User) => {
    return incomingFriendRequests.some(friendRequest => friendRequest.getUid() === user.getUid());
  };

  const handleRemoveFriend = async (friend: CometChat.User) => {
    if (!loggedInCometChatUser) return;

    await removeFriend(functions, friend);
    setAlert({ message: 'Vriend verwijderd', type: 'success' });
    loadFriends();
  };

  const handleAcceptFriendRequest = async (request: FriendRequest) => {
    if (!loggedInCometChatUser) return;

    await acceptFriendRequest(functions, request);
    setAlert({ message: 'Vriendschapsverzoek geaccepteerd', type: 'success' });
    loadIncomingFriendRequests();
    loadFriends();
  };

  const handleFriendRequest = async (user: CometChat.User) => {
    // Can only send friend requests if logged in
    if (!loggedInCometChatUser) return;

    try {
      await sendFriendRequest(functions, user.getUid());
      setAlert({ message: 'Vriendschapsverzoek verstuurd', type: 'success' });

      // Reload the list of outgoing friend requests
      loadOutgoingFriendRequests();
    } catch (error) {
      console.error("Error sending friend request:", error);
      setAlert({ message: 'Er is iets misgegaan bij het versturen van het vriendschapsverzoek. Probeer het later opnieuw.', type: 'error' });
    }
  };

  const handleAddOrRemoveFriend = async (user: CometChat.User) => {
    try {
      if (friends.some(friend => friend.getUid() === user.getUid())) {
        // Remove friend
        await handleRemoveFriend(user);
      } else if (hasReceivedFriendRequest(user)) {
        const friendRequest = incomingFriendRequests.find(friendRequest => friendRequest.getUid() === user.getUid());
        if (!friendRequest) return;
        handleAcceptFriendRequest(friendRequest);
      } else {
        // Send friend request
        await handleFriendRequest(user);
      }
    } catch (error) {
      console.error("Error adding/removing friend:", error);
    }
  };

  const handleBanUser = async (user: CometChat.User) => {
    const banUser = httpsCallable(functions, 'banUser');

    try {
      await banUser({ uid: user.getUid() });
      setAlert({ message: `Gebruiker ${user.getName()} is verbannen`, type: 'success' });
    } catch {
      setAlert({ message: `Er is iets misgegaan bij het verbannen van ${user.getName()}`, type: 'error' });
    }
  }

  const handleKickUser = async (user: CometChat.User, group: CometChat.Group) => {
    const kickUser = httpsCallable(functions, 'kickUser');

    try {
      await kickUser({ uid: user.getUid(), groupName: group.getName(), guid: group.getGuid() });
      setAlert({ message: `Gebruiker ${user.getName()} is gekicked`, type: 'success' });

    } catch (e) {
      console.error("Error kicking user:", e);
      setAlert({ message: `Er is iets misgegaan bij het kicken van ${user.getName()}`, type: 'error' });
    }
  }

  const handleMakeUserAdmin = async (user: CometChat.User) => {
    const makeUserAdmin = httpsCallable(functions, 'makeUserAdmin');

    try {
      await makeUserAdmin({ uid: user.getUid() });
      setAlert({ message: `Gebruiker ${user.getName()} is nu admin`, type: 'success' });
    } catch {
      setAlert({ message: `Er is iets misgegaan bij het promoveren van ${user.getName()}`, type: 'error' });
    }
  }

  const handleLeaveChannel = (channel: ActiveChannelInterface) => {
    if (channel.isGroup && channel.isOpen) {
      CometChat.leaveGroup(channel.id).catch(error => {
        console.error("Group leaving failed with exception:", error);
      });
    }

    setActiveChannels(prev => prev.filter(c => c.id !== channel.id));
    if (currentChat.id === channel.id) {
      setCurrentChat({ id: '', icon: '', name: '', joinedAt: 0, isGroup: false });
    }

    // Remove the notifications about the left channel
    const { [channel.id]: _, ...rest } = unreadCount;
    setUnreadCount(rest);
  };

  // Listen to group members join and leave events and update the members list of the channel
  const listenToGroupMembers = (currentChat: ActiveChannelInterface, members: CometChat.GroupMember[]) => {
    const groupListenerId = 'group-members-listener';

    // Remove previous listener
    CometChat.removeGroupListener(groupListenerId);
    // Add group listener to handle member join and leave events
    CometChat.addGroupListener(
      groupListenerId,
      new CometChat.GroupListener({
        onMemberAddedToGroup: (message: CometChat.BaseMessage, userAdded: CometChat.User, userAddedBy: any, userAddedIn: CometChat.Group) => {
          if (userAddedIn.getGuid() === currentChat.id) {
            // Add the new member to the members list
            const newMember = new CometChat.GroupMember(userAdded.getUid(), 'participant');
            newMember.setAvatar(userAdded.getAvatar());
            newMember.setBlockedByMe(userAdded.getBlockedByMe());
            newMember.setName(userAdded.getName());
            newMember.setStatus(userAdded.getStatus());
            newMember.setMetadata(userAdded.getMetadata());
            newMember.setRole(userAdded.getRole());

            const newMembers = [...members, newMember];
            setMembers(newMembers);

            if (message.getCategory() === 'action' && !showLoadMostRecentMsgBtn) {
              setMessages(prevMessages => [...prevMessages, message]);
              // Set message to read
              CometChat.markAsRead(message);
            }
          }
        },
        onGroupMemberJoined: (_: CometChat.Action, joinedUser: CometChat.User, joinedGroup: any) => {
          if (joinedGroup.getGuid() === currentChat.id) {
            const newMember = new CometChat.GroupMember(joinedUser.getUid(), 'participant');
            newMember.setAvatar(joinedUser.getAvatar());
            newMember.setBlockedByMe(joinedUser.getBlockedByMe());
            newMember.setName(joinedUser.getName());
            newMember.setStatus('online');
            newMember.setMetadata(joinedUser.getMetadata());
            newMember.setRole(joinedUser.getRole());

            const newMembers = [...members, newMember];
            setMembers(newMembers);
          }
        },
        onGroupMemberLeft: (message: CometChat.Action, leftUser: CometChat.User, leftGroup: CometChat.Group) => {
          if (leftGroup.getGuid() === currentChat.id) {
            setMembers(members.filter(member => member.getUid() !== leftUser.getUid()));

            if (!currentChat.isOpen && !showLoadMostRecentMsgBtn) {
              // Display that the user left (not visible in public channels)
              setMessages(prevMessages => [...prevMessages, message]);
              // Set message to read
              CometChat.markAsRead(message);
            }
          }
        },
        onGroupMemberKicked: (message: CometChat.Action, kickedUser: CometChat.User, kickedBy: CometChat.User, kickedFrom: CometChat.Group) => {
          if (kickedFrom.getGuid() === currentChat.id) {
            setMembers(members.filter(member => member.getUid() !== kickedUser.getUid()));

            if (!currentChat.isOpen && !showLoadMostRecentMsgBtn) {
              // Display that the user was kicked (not visible in public channels)
              setMessages(prevMessages => [...prevMessages, message]);
              // Set message to read
              CometChat.markAsRead(message);
            }
          }
        },
        // This only works for bans per group (not for global bans)
        // onGroupMemberBanned: (message: CometChat.Action, bannedUser: CometChat.User, bannedBy: CometChat.User, bannedFrom: CometChat.Group) => {
        //   if (bannedFrom.getGuid() === currentChat.id) {
        //     setMembers(members.filter(member => member.getUid() !== bannedUser.getUid()));

        //     if (!currentChat.isOpen && !showLoadMostRecentMsgBtn) {
        //       // Display that the user was banned (not visible in public channels)
        //       setMessages(prevMessages => [...prevMessages, message]);
        //       // Set message to read
        //       CometChat.markAsRead(message);
        //     }
        //   }
        // },
      })
    );
  }

  useEffect(() => {
    listenToGroupMembers(currentChat, members);
  }, [currentChat, members]);

  if (!loggedInCometChatUser || isLoading) {
    !loggedInCometChatUser && navigate('/login');
    return <LoadingPage />;
  } else {
    // Redirect to registration step 2 if the user hasn't set their birthdate yet
    const metadata = getProfileFromMetadata(loggedInCometChatUser);
    if (metadata.about.birthDate === 'unknown') navigate('/register-step-2');
  }

  return (
    <section className={`chat-page ${generalFontSize}`}>
      <Header
        loggedInUser={loggedInCometChatUser}
        notifications={notifications}
        handleLogout={handleLogout}
        setNotifications={setNotifications}
        unreadCount={unreadCount}
        conversations={conversations}
        handleChangeConversation={handleChangeConversation}
        handleChangeGroupConversation={handleChangeGroupConversation}
        setActiveTabIndex={setActiveTabIndex}
        setCurrentChat={setCurrentChat}
        generalFontSize={generalFontSize}
      />

      <div className="chat-body">
        <ConversationSDK
          setNotifications={setNotifications}
          unreadCount={unreadCount}
          setUnreadCount={setUnreadCount}
          currentChat={currentChat}
          setCurrentChat={setCurrentChat}
          activeChannels={activeChannels}
          handleLeaveChannel={handleLeaveChannel}

          handleChangeConversation={handleChangeConversation}
          handleGroupConversationClick={handleChangeGroupConversation}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          loggedInUser={loggedInCometChatUser}

          conversations={conversations}
          setConversations={setConversations}
          initialConversation={initialConversation}
          setInitialConversation={setInitialConversation}
          refreshConversations={refreshConversations}

          incomingFriendRequests={incomingFriendRequests}
          loadIncomingFriendRequests={loadIncomingFriendRequests}
          outgoingFriendRequests={outgoingFriendRequests}
          loadOutgoingFriendRequests={loadOutgoingFriendRequests}
          blockedUsers={blockedUsers}
          handleBlockUser={handleBlockUser}
          friends={friends}
          loadFriends={loadFriends}
          setFriends={setFriends}
          handleRemoveFriend={handleRemoveFriend}
          handleAcceptFriendRequest={handleAcceptFriendRequest}
          handleFriendRequest={handleFriendRequest}
          hasSentFriendRequest={hasSentFriendRequest}
          hasReceivedFriendRequest={hasReceivedFriendRequest}
          handleAddOrRemoveFriend={handleAddOrRemoveFriend}
          handleBanUser={handleBanUser}
          handleKickUser={handleKickUser}
          handleMakeUserAdmin={handleMakeUserAdmin}

          members={members}
          setMembers={setMembers}
          chatWithUser={chatWithUser}
          setChatWithUser={setChatWithUser}
          channels={channels}
          setAlert={setAlert}

          messages={messages}
          setMessages={setMessages}
          activeColorState={activeColorState}
          setActiveColorState={setActiveColorState}
          messageFontSize={messageFontSize}
          setMessageFontSize={setMessageFontSize}
          generalFontSize={generalFontSize}
          setGeneralFontSize={setGeneralFontSize}

          showLoadMostRecentMsgBtn={showLoadMostRecentMsgBtn}
          setShowLoadMostRecentMsgBtn={setShowLoadMostRecentMsgBtn}

          functions={functions}
          handleLogout={handleLogout}

          handleChangeChannel={handleChangeChannel}
        />
      </div>
    </section>
  );
}
