import './styles.css';
import chatplazaLogo2Svg from '../../assets/chatplaza-logo-2.svg';
import { IconChevronRight } from "anchor-ui/icons";

const Footer = () => {
  return (
    <footer className='footer'>
      <section>
        <article>
          <h3>Informatie</h3>
          <ul>
            <li>
              <a href="/chat-rules"><IconChevronRight />Chatregels</a>
            </li>
            <li>
              <a href="/terms"><IconChevronRight />Algemene voorwaarden</a>
            </li>
            <li>
              <a href="/contact"><IconChevronRight />Contact</a>
            </li>
          </ul>
        </article>
        <article>
          <h3>Populair</h3>
          <p>
            <a href="/chat"><em>Chatten</em></a>
            <a href="/chat">Chatbox</a>
            <a href="/chat"><em>Chat</em></a>
            <a href="/chat">Jongeren community</a>
            <a href="/chat">Veilig chatten</a>
            <a href="/chat">Chatten met profiel</a>
            <a href="/chat"><em>Gratis chat</em></a>
            <a href="/chat">Jongeren forum</a>
            <a href="/chat">Online vriendschap</a>
            <a href="/chat"><em>Chat met vreemden</em></a>
            <a href="/chat"><em>Gezellig chatten</em></a>
            <a href="/chat">Mobiel Chatten</a>
            <a href="/chat">Online gebruikers</a>
          </p>
        </article>
        <article>
          <img src={chatplazaLogo2Svg} alt="ChatPlaza logo" />
        </article>
        <p className="copyright">© 1997-{new Date().getFullYear()} Chatten B.V. · All rights reserved.</p>
      </section>
    </footer>
  )
}

export default Footer;