import React, { useEffect, useState, useRef, useCallback } from 'react';
import { CometChat } from '@cometchat-pro/chat';
import { ActiveChannelInterface } from './../../interfaces/activeChannelInterface';
import './styles.css';
import MessageComponent from './MessageComponent';
import MessageListComponent from './MessageListComponent';
import MessageInputComponent from './MessageInputComponent';
import ChannelHeaderComponent from './ChannelHeader';
import { getDateFromTimestamp } from '../getDateFromTimestamp';
import { NotificationInterface } from './../../interfaces/notificationInterface';
import EditMessageModal from './EditMessageModal';
import ReportMessageModal from './../../components/ReportModal/ReportMessageModal';
import { checkIfUserIsBlocked } from '../../utils/checkIfUserIsBlocked';
import Message from 'anchor-ui/message';
import chatBotImg from './../../assets/chatbot.jpg';
import AdminBadge from 'anchor-ui/admin-badge';
import Button from 'anchor-ui/button';

interface ChatComponentProps {
  currentChat: ActiveChannelInterface;
  setCurrentChat: React.Dispatch<React.SetStateAction<ActiveChannelInterface>>;
  conversations: any;
  setConversations: (conversation: any) => void;
  setLastConversationMsg: React.Dispatch<React.SetStateAction<{ conversationId: string; receiverId: string; message: string; }>>;
  loggedInUser: CometChat.User | null;
  loadIncomingFriendRequests: () => void;
  loadOutgoingFriendRequests: () => void;
  setNotifications: React.Dispatch<React.SetStateAction<NotificationInterface[] | null>>;
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  friends: CometChat.User[];
  hasSentFriendRequest: (user: CometChat.User) => boolean;
  hasReceivedFriendRequest: (user: CometChat.User) => boolean;
  handleAddOrRemoveFriend: (friend: CometChat.User) => void;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  messages: CometChat.BaseMessage[];
  setMessages: React.Dispatch<React.SetStateAction<CometChat.BaseMessage[]>>;
  leftMenuOpen: boolean;
  setLeftMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  rightMenuOpen: boolean;
  setRightMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  groupMembers?: CometChat.GroupMember[];
  chatWithUser?: CometChat.User;
  showLoadMostRecentMsgBtn: boolean;
  setShowLoadMostRecentMsgBtn: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatComponent: React.FC<ChatComponentProps> = ({
  currentChat,
  setCurrentChat,
  conversations,
  setConversations,
  setLastConversationMsg,
  loggedInUser,
  loadIncomingFriendRequests,
  loadOutgoingFriendRequests,
  blockedUsers,
  handleBlockUser,
  friends,
  hasSentFriendRequest,
  hasReceivedFriendRequest,
  handleAddOrRemoveFriend,
  setUserToReport,
  setAlert,

  messages,
  setMessages,

  leftMenuOpen,
  setLeftMenuOpen,
  rightMenuOpen,
  setRightMenuOpen,
  unreadCount,

  groupMembers,
  chatWithUser,

  showLoadMostRecentMsgBtn,
  setShowLoadMostRecentMsgBtn,
}) => {
  const [messageInput, setMessageInput] = useState('');
  const [isFetching, setIsFetching] = useState(true);

  const [editMessage, setEditMessage] = useState<CometChat.TextMessage | null>(null);
  const [messageToReport, setMessageToReport] = useState<CometChat.BaseMessage | null>(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);

  const [emojiMenuOpen, setEmojiMenuOpen] = useState(false);
  const [commandMenuOpen, setCommandMenuOpen] = useState(false);

  const [pinnedMessages, setPinnedMessages] = useState<CometChat.BaseMessage[]>([]);

  const messageEndRef = useRef<HTMLDivElement | null>(null);
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(true);

  const loadIncomingFriendRequestsRef = useRef(loadIncomingFriendRequests);
  const loadOutgoingFriendRequestsRef = useRef(loadOutgoingFriendRequests);

  const handleMessageInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageInput(event.target.value);
  };

  const handleScrollToBottom = () => {
    if (!isScrolledToBottom) return;
    messageEndRef.current?.scrollIntoView();
  };

  // const fetchPinnedMessages = async (receiverType: string, receiver: string) => {
  //   const URL = `v1/fetch?receiverType=${receiverType}&receiver=${receiver}`;
  //   try {
  //     const response = await CometChat.callExtension('pin-message', 'GET', URL) as { pinnedMessages: any[] };
  //     const resMessages = response.pinnedMessages;

  //     // The pinned messages extension does not return user objects for senders (only uid)
  //     // Extract sender UIDs
  //     const uids = resMessages.map((message: any) => message.sender) as string[];

  //     // Fetch user details
  //     const usersRequest = new CometChat.UsersRequestBuilder()
  //       .setUIDs(uids)
  //       .setLimit(resMessages.length)
  //       .build();
  //     const users: CometChat.User[] = await usersRequest.fetchNext();

  //     // Add loggedInUser to users
  //     if (loggedInUser) {
  //       users.push(loggedInUser);
  //     }

  //     // Add getSender method to each message and handle poll messages
  //     for (let i = 0; i < resMessages.length; i++) {
  //       const message = resMessages[i];
  //       const user = users.find((user: CometChat.User) => user.getUid() === message.sender);

  //       if (user) {
  //         let messageInstance = message;

  //         if (message.type === CometChat.MESSAGE_TYPE.TEXT) {
  //           messageInstance = new CometChat.TextMessage(message.receiver, message.data.text, message.type);
  //         } else if (message.type === CometChat.MESSAGE_TYPE.IMAGE) {
  //           messageInstance = new CometChat.MediaMessage(message.receiver, message.data.url, message.type, message.receiverType);
  //         }

  //         messageInstance.getSender = () => user;
  //         messageInstance.getId = () => message.id;
  //         messageInstance.getSentAt = () => message.sentAt;
  //         messageInstance.getType = () => message.type;
  //         messageInstance.getCategory = () => message.category;
  //         messageInstance.getDeletedAt = () => message.deletedAt;
  //         messageInstance.getEditedAt = () => message.editedAt;

  //         if (message.type === 'extension_poll') {
  //           const pollMsg = await CometChat.getMessageDetails(message.id);
  //           resMessages[i] = pollMsg;
  //         } else {
  //           resMessages[i] = messageInstance;
  //         }
  //       } else {
  //         throw new Error(`User not found for message sender: ${message.sender}`);
  //       }
  //     }

  //     // Set pinned messages
  //     setPinnedMessages(resMessages);
  //   } catch (error) {
  //     console.error('Error fetching pinned messages:', error);
  //     setAlert({ message: 'Vastgezette berichten konden niet worden opgehaald.', type: 'error' });
  //   }
  // };

  // Utility function to build the MessagesRequest
  const buildMessagesRequest = (fetchFromTimestamp: number, limit: number, currentChat: any) => {
    const isUser = !currentChat.isGroup && currentChat.receiverId;
    const targetId = isUser ? currentChat.receiverId : currentChat.id;
    return new CometChat.MessagesRequestBuilder()
    [isUser ? 'setUID' : 'setGUID'](targetId + '')
      .setTimestamp(fetchFromTimestamp)
      .setLimit(limit)
      .build();
  };

  // Utility function to filter valid messages
  const filterValidMessages = (messageList: CometChat.BaseMessage[], messages: CometChat.BaseMessage[], currentChat: any, checkDuplicate?: boolean) => {
    return messageList.filter(message => {
      if (checkDuplicate) {
        const isDuplicate = messages.some(msg => msg.getId() === message.getId());
        if (isDuplicate) return false;
      }

      const isTextMessage = message instanceof CometChat.TextMessage;
      const isMediaMessage = message instanceof CometChat.MediaMessage;
      const isActionMessage = message.getCategory() === 'action';
      const isGroupMemberMessage = message.getType() === 'groupMember' && (message as any).getMessage();
      const isPollMessage = message.getType() === 'extension_poll';
      const isCustomMessage = message instanceof CometChat.CustomMessage;

      if (currentChat.isOpen && isActionMessage) return false;

      if (isCustomMessage && !isPollMessage) {
        const tags = (message.getCustomData() as any)?.tags;
        return tags?.includes('reload_pinned') ?? false;
      }

      const hasValidContent = isTextMessage ? message.getText() : isMediaMessage ? message.getURL() : false;

      return ((isTextMessage || isMediaMessage || isActionMessage) && hasValidContent) || isGroupMemberMessage || isPollMessage;
    });
  };

  // Fetch the most recent 50 messages for the current chat
  const fetchMessages = useCallback(async () => {
    setIsFetching(true);
    setMessages([]);
    const timestamp = currentChat.isOpen ? Math.floor(currentChat.joinedAt / 1000) : 0;
    let messagesRequest = buildMessagesRequest(timestamp, 50, currentChat);

    try {
      const request = currentChat.isOpen ? messagesRequest.fetchNext() : messagesRequest.fetchPrevious();
      const messageList = await request;

      setShowLoadMoreBtn(messageList.length === 50);
      const validMessages = filterValidMessages(messageList, messages, currentChat);
      setMessages(validMessages);
      setShowLoadMostRecentMsgBtn(false);
      setIsFetching(false);
    } catch (error) {
      console.error('Message fetching failed with error:', error);
      setAlert({ message: 'Berichten konden niet worden opgehaald', type: 'error' });
    }
  }, [currentChat, setAlert]);

  const handleSendImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files == null || event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];
    const mediaMessage = new CometChat.MediaMessage(
      currentChat.receiverId ? currentChat.receiverId : currentChat.id,
      file,
      CometChat.MESSAGE_TYPE.IMAGE,
      currentChat.isGroup ? CometChat.RECEIVER_TYPE.GROUP : CometChat.RECEIVER_TYPE.USER
    );

    CometChat.sendMediaMessage(mediaMessage).then(
      message => {
        if (message instanceof CometChat.MediaMessage) {

          // Set last conversation message if chat is not open
          if (!currentChat.isOpen) {
            setLastConversationMsg({
              conversationId: currentChat.id,
              receiverId: message.getReceiverId(),
              message: 'Afbeelding'
            });
          }

          // Update message list
          if (showLoadMostRecentMsgBtn) fetchMessages();
          else setMessages(prevMessages => [...prevMessages, message]);

          // Update conversation list
          if (currentChat.id) {
            const isGroup = currentChat.isGroup;
            const conversationIndex = conversations.findIndex((conversation: any) =>
              isGroup ? conversation.guid : conversation.conversationId === currentChat.id
            );
            const conversation = conversations[conversationIndex];
            if (conversation) {
              conversations.splice(conversationIndex, 1);

              setConversations([conversation, ...conversations]);
            }
          }
        }
      },
      error => {
        console.error('Image sending failed with error:', error);
        setAlert({ message: 'Afbeelding kon niet worden verzonden', type: 'error' });
      }
    );
  };

  const handleSendMessage = (event: any, messageText?: string) => {
    if (commandMenuOpen && event.key === 'Enter') return;
    const textInput = messageText ?? messageInput;
    // Don't send message if the message input is empty
    if (!textInput?.trim()) return;

    let textMessage;
    if (currentChat.id) {
      textMessage = new CometChat.TextMessage(
        currentChat.receiverId ? currentChat.receiverId : currentChat.id,
        textInput,
        currentChat.isGroup ? CometChat.RECEIVER_TYPE.GROUP : CometChat.RECEIVER_TYPE.USER
      );
    }

    CometChat.sendMessage(textMessage).then(
      message => {
        if (message instanceof CometChat.TextMessage) {

          if (!currentChat.isOpen) setLastConversationMsg({ conversationId: currentChat.id, receiverId: message.getReceiverId(), message: message.getText() });
          if (showLoadMostRecentMsgBtn) fetchMessages();
          else setMessages(prevMessages => [...prevMessages, message]);

          if (currentChat.id) {
            const isGroup = currentChat.isGroup;
            const conversationIndex = conversations.findIndex((conversation: any) => isGroup ? conversation.guid : conversation.conversationId === currentChat.id);
            const conversation = conversations[conversationIndex];
            if (conversation) {
              conversations.splice(conversationIndex, 1);

              setConversations([conversation, ...conversations]);
            }
          }
        }
        setMessageInput('');
      },
      error => {
        console.error('--> Message sending failed with error:', error);
        setAlert({ message: 'Bericht kon niet worden verzonden', type: 'error' });
      }
    );
  };

  // Fetch older messages for the current chat
  const fetchOlderMessages = async () => {
    const fetchFromTimestamp = messages[0]?.getSentAt() ?? 0;
    const scrollToId = messages[0]?.getId() + '';
    let messagesRequest = buildMessagesRequest(fetchFromTimestamp, 50, currentChat);

    try {
      const messageList = await messagesRequest.fetchPrevious();
      if (messageList.length < 50) setShowLoadMoreBtn(false);

      const validMessages = filterValidMessages(messageList, messages, currentChat, true);
      const updatedMessages = [...validMessages, ...messages];

      // If the updatedMessages array is over 150 messages, remove the last 50 messages
      if (updatedMessages.length > 150) {
        updatedMessages.splice(150, 50); // Remove older messages
        setShowLoadMostRecentMsgBtn(true);
      }

      setMessages(updatedMessages);

      // Scroll to the previous oldest message fetched
      const previousOldestMsg = document.getElementById(scrollToId);
      if (previousOldestMsg) previousOldestMsg.scrollIntoView();
    } catch (error) {
      console.error('Message fetching failed with error:', error);
      setAlert({ message: 'Berichten konden niet worden opgehaald', type: 'error' });
    }
  };

  useEffect(() => {
    loadIncomingFriendRequestsRef.current = loadIncomingFriendRequests;
    loadOutgoingFriendRequestsRef.current = loadOutgoingFriendRequests;
  }, [loadIncomingFriendRequests, loadOutgoingFriendRequests]);

  const handleDeleteMessage = (messageId: string) => {
    CometChat.deleteMessage(messageId).then(
      () => {
        setMessages(prevMessages => prevMessages.filter(message => message.getId() + '' !== messageId));
      },
      error => {
        console.error('Message deleting failed with error:', error);
        setAlert({ message: 'Bericht kon niet worden verwijderd', type: 'error' });
      }
    );
  }

  const handleSendPinMessage = async (pinMsg: string) => {
    if (!loggedInUser) return;

    let customMessage;
    if (currentChat.id) {
      customMessage = new CometChat.CustomMessage(
        currentChat.receiverId ? currentChat.receiverId : currentChat.id,
        currentChat.isGroup ? CometChat.RECEIVER_TYPE.GROUP : CometChat.RECEIVER_TYPE.USER,
        'custom',
        { tags: ['reload_pinned'], messageInput: loggedInUser.getName() + ' ' + pinMsg }
      );
    }

    try {
      const message = await CometChat.sendMessage(customMessage);

      if (message instanceof CometChat.CustomMessage) {
        if (!currentChat.isOpen) {
          setLastConversationMsg({ conversationId: currentChat.id, receiverId: message.getReceiverId(), message: message.getData().messageInput });
        }
        if (showLoadMostRecentMsgBtn) fetchMessages();
        else setMessages(prevMessages => [...prevMessages, message]);

        if (currentChat.id) {
          const isGroup = currentChat.isGroup;
          const conversationIndex = conversations.findIndex((conversation: any) => isGroup ? conversation.guid : conversation.conversationId === currentChat.id);
          const conversation = conversations[conversationIndex];
          if (conversation) {
            conversations.splice(conversationIndex, 1);
            setConversations([conversation, ...conversations]);
          }
        }
      }
      setMessageInput('');
    } catch (error) {
      console.error('Message sending failed with error:', error);
      setAlert({ message: 'Bericht kon niet worden verzonden', type: 'error' });
    }
  };

  const handlePinMessage = async (message: CometChat.BaseMessage) => {
    try {
      await CometChat.callExtension('pin-message', 'POST', 'v1/pin', {
        msgId: message.getId() // Use the provided messageId
      });
      // Send a message to the chat to notify users that a message has been pinned
      handleSendPinMessage('heeft een bericht vastgezet.');
      // Add message to pinned messages
      setPinnedMessages(prevMessages => [...prevMessages, message]);
      setAlert({ message: 'Bericht vastgezet', type: 'success' });
    } catch (error) {
      console.error('Error pinning message:', error);
      setAlert({ message: 'Bericht kon niet worden vastgezet', type: 'error' });
    }
  };

  const handleUnpinMessage = async (message: CometChat.BaseMessage) => {
    try {
      await CometChat.callExtension('pin-message', 'DELETE', 'v1/unpin', {
        msgId: message.getId(),
        receiverType: currentChat.isGroup ? 'group' : 'user',
        receiver: currentChat.id
      });
      // Send a message to the chat to notify users that a message has been unpinned
      handleSendPinMessage('heeft een bericht losgemaakt.');
      // Remove message from pinned messages
      setPinnedMessages(prevMessages => prevMessages.filter(pinnedMessage => pinnedMessage.getId() !== message.getId()));
      setAlert({ message: 'Bericht losgemaakt', type: 'success' });
    } catch (error) {
      console.error('Error unpinning message:', error);
      setAlert({ message: 'Bericht kon niet worden losgemaakt', type: 'error' });
    }
  }

  useEffect(() => {
    const listenerId = 'message-listener';

    CometChat.addMessageListener(
      listenerId,
      new CometChat.MessageListener({
        onTextMessageReceived: (message: CometChat.TextMessage) => {
          if (message.getReceiverId() === currentChat.id ||
            message.getConversationId() === currentChat.id ||
            message.getSender().getUid() === currentChat.id && !showLoadMostRecentMsgBtn) {
            setMessages(prevMessages => [...prevMessages, message]);
          }
        },
        onMediaMessageReceived: (message: CometChat.BaseMessage) => {
          if (message.getReceiverId() === currentChat.id ||
            message.getConversationId() === currentChat.id ||
            message.getSender().getUid() === currentChat.id
          ) {
            CometChat.markAsRead(message);

            if (!showLoadMostRecentMsgBtn) setMessages(prevMessages => [...prevMessages, message]);
          }
        },
        onCustomMessageReceived: (message: CometChat.CustomMessage) => {
          let tags = message.getTags();
          if (!tags) tags = (message.getCustomData() as any).tags;

          if (tags) {
            if (tags.includes('friend_request') || tags.includes('reload_requests')) {
              loadIncomingFriendRequestsRef.current();
              return;
            }

            if (tags.includes('reload_requests')) {
              loadOutgoingFriendRequestsRef.current();
              return;
            }

            // if (tags.includes('reload_pinned')) {
            //   fetchPinnedMessages(currentChat.isGroup ? 'group' : 'user', currentChat.id);
            //   setMessages(prevMessages => [...prevMessages, message]);
            //   return;
            // }
          }

          if (message.getType() === 'extension_poll' && !showLoadMostRecentMsgBtn) {
            setMessages(prevMessages => [...prevMessages, message]);
          }
        },
        onMessageDeleted: (message: CometChat.BaseMessage) => {
          setMessages(prevMessages => prevMessages.filter(msg => msg.getId() !== message.getId()));
        },
        onMessageEdited: (message: CometChat.BaseMessage) => {
          setMessages(prevMessages => {
            const updatedMessages = prevMessages.map(msg => {
              if (msg.getId() === message.getId()) {
                return message;
              }
              return msg;
            });
            return updatedMessages;
          });
        },
      })
    );

    fetchMessages();

    // Scroll to bottom when chat is opened
    setIsScrolledToBottom(true);

    return () => {
      CometChat.removeMessageListener(listenerId);
    };
  }, [currentChat, fetchMessages]);

  useEffect(() => {
    const handleScroll = () => {
      if (messageEndRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = messageEndRef.current.parentElement!;
        setIsScrolledToBottom(scrollTop + clientHeight >= scrollHeight);
      }
    };

    const messageListElement = messageEndRef.current?.parentElement;
    messageListElement?.addEventListener('scroll', handleScroll);

    // Use MutationObserver to detect changes in the DOM (new messages or images)
    const observer = new MutationObserver(() => {
      handleScrollToBottom();
    });

    if (messageEndRef.current) {
      observer.observe(messageEndRef.current.parentElement!, { childList: true, subtree: true });
    }

    return () => {
      messageListElement?.removeEventListener('scroll', handleScroll);
      observer.disconnect();
    };
  }, [isScrolledToBottom]);

  const getIsPinned = (message: CometChat.BaseMessage) => {
    if (currentChat.showPinnedMessages) return true;
    return pinnedMessages.some(pinnedMessage => pinnedMessage.getId() === message.getId());
  }

  return (
    <div className="chat-component">
      <EditMessageModal
        editMessage={editMessage}
        setEditMessage={setEditMessage}
        setMessages={setMessages}
        setAlert={setAlert}
      />
      <ReportMessageModal
        messageToReport={messageToReport}
        setMessageToReport={setMessageToReport}
        setAlert={setAlert}
      />

      <ChannelHeaderComponent
        channel={currentChat}
        blockedUsers={blockedUsers}
        handleBlockUser={handleBlockUser}
        currentChat={currentChat}
        setCurrentChat={setCurrentChat}
        friends={friends}
        hasSentFriendRequest={hasSentFriendRequest}
        hasReceivedFriendRequest={hasReceivedFriendRequest}
        handleAddOrRemoveFriend={handleAddOrRemoveFriend}
        setUserToReport={setUserToReport}
        leftMenuOpen={leftMenuOpen}
        setLeftMenuOpen={setLeftMenuOpen}
        rightMenuOpen={rightMenuOpen}
        setRightMenuOpen={setRightMenuOpen}
        unreadCount={unreadCount}
      />
      <div className={`message-list ${emojiMenuOpen ? 'emoji-padding' : commandMenuOpen ? 'command-padding' : ''}`}>

        <MessageListComponent>

          {showLoadMoreBtn && messages.length > 0 && (
            <Button
              inverted
              onClick={fetchOlderMessages}
            >
              Laad Meer Berichten
            </Button>
          )}

          {(currentChat.showPinnedMessages ? pinnedMessages : messages).map((message, index) => (
            <MessageComponent
              key={message.getId()}
              message={message}
              myMessage={loggedInUser !== null && message.getSender().getUid() === loggedInUser.getUid()}
              showMessageSeparator={index === 0 || getDateFromTimestamp(message.getSentAt()) !== getDateFromTimestamp((currentChat.showPinnedMessages ? pinnedMessages : messages)[index - 1].getSentAt())}
              isBlocked={checkIfUserIsBlocked(blockedUsers, message.getSender().getUid())}
              handleDeleteMessage={handleDeleteMessage}
              setEditMessage={setEditMessage}
              setMessageToReport={setMessageToReport}
              handleScrollToBottom={handleScrollToBottom}
              groupMembers={groupMembers}
              loggedInUser={loggedInUser ?? undefined}
              setCurrentChat={setCurrentChat}
              setAlert={setAlert}
            />
          ))}

          {/* Display a message from the ChatBot if the chat is a private chat and the user has not sent any messages yet: */}
          {(!currentChat.isGroup && chatWithUser) && messages.length === 0 && !isFetching && (
            <Message
              avatar={chatBotImg}
              body={
                <span className='chatbot-msg'>
                  Deel nooit gegevens met vreemden en open nooit onbekende urls. ChatPlaza en moderators zullen nooit vragen naar accountgegevens of vragen om betalingen te doen. Kom je iets verdachts tegen? Rapporteer het bericht en/of de gebruiker.
                </span>
              }
              createdAt={
                <span className='chatbot-msg'>
                  {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                </span>
              }
              username={
                <>
                  <span>ChatBot </span>
                  <AdminBadge text="bot" />
                  <small> Alleen zichtbaar voor jou</small>
                </>
              }
            />
          )}

          {showLoadMostRecentMsgBtn && messages.length > 0 && (
            <Button
              inverted
              onClick={fetchMessages}
            >
              Laad Recente Berichten
            </Button>
          )}
          <div ref={messageEndRef} />
        </MessageListComponent>
      </div>

      <MessageInputComponent
        handleChange={handleMessageInputChange}
        value={messageInput}
        handleSendMessage={handleSendMessage}
        handleSendImage={handleSendImage}
        groupMembers={groupMembers}
        chatWithUser={chatWithUser}

        emojiMenuOpen={emojiMenuOpen}
        setEmojiMenuOpen={setEmojiMenuOpen}
        setCommandMenuOpen={setCommandMenuOpen}
        currentChat={currentChat}
      />
    </div>
  );
};

export default ChatComponent;