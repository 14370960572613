import { metadataInterface } from "../interfaces/metadataInterface";
import { CometChat } from "@cometchat-pro/chat";

export const getProfileFromMetadata = (user: CometChat.User) => {
  const metadata = user.getMetadata() as unknown as metadataInterface;

  if (metadata === undefined) {
    return {
      about: {
        gender: 'unknown',
        birthDate: 'unknown',
      }
    }

  };

  if (!metadata || typeof metadata === 'string') {
    const jsonParsedMetadata = JSON.parse(metadata as string) as metadataInterface;
    return jsonParsedMetadata;
  } 

  return metadata;
}

export const updateUserMetadata = async (updatedMetadata: metadataInterface, user: CometChat.User): Promise<metadataInterface | boolean> => {
  try {
    user.setMetadata(JSON.stringify(updatedMetadata));
    await CometChat.updateCurrentUserDetails(user);

    return updatedMetadata;
  } catch (error) {
    console.error("error", error);
    return false;
  }
};