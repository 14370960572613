import { CometChat } from '@cometchat-pro/chat';

import Message from 'anchor-ui/message';
import MessageSeparator from 'anchor-ui/message-separator';
import ImageLoader from 'anchor-ui/image-loader';
import IconMenu from 'anchor-ui/icon-menu';
import MenuItem from 'anchor-ui/menu-item';
import {
  IconChevronDown,
  IconDelete,
  IconEdit,
  IconReport
} from 'anchor-ui/icons';

import defaultAvatar from "./../../assets/default_avatar.jpg";
import PollMessage from './PollMessage';
import ImageDialog from './ImageDialog';
import { useState } from 'react';

interface MessageComponentProps {
  message: CometChat.BaseMessage;
  myMessage: boolean;
  showMessageSeparator: boolean;
  isBlocked: boolean;
  handleDeleteMessage: (messageId: string) => void;
  setEditMessage: (editMessage: CometChat.TextMessage | null) => void;
  setMessageToReport: (messageToReport: CometChat.BaseMessage) => void;
  handleScrollToBottom: () => void;
  groupMembers?: CometChat.GroupMember[];
  loggedInUser?: CometChat.User;
  setCurrentChat: (chat: any) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
}

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
};

const formatTime = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};

const MessageComponent: React.FC<MessageComponentProps> = ({
  message,
  myMessage,
  showMessageSeparator,
  isBlocked,
  handleDeleteMessage,
  setEditMessage,
  setMessageToReport,
  handleScrollToBottom,
  groupMembers,
  loggedInUser,
  setCurrentChat,
  setAlert,
}) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const avatar = isBlocked ? defaultAvatar : message.getSender()?.getAvatar() || defaultAvatar;
  const username = isBlocked ? '[Geblokkeerde gebruiker]' : message.getSender()?.getName();
  const createdAt = formatTime(message.getSentAt());
  const separator = showMessageSeparator ? <MessageSeparator text={formatDate(message.getSentAt())} /> : null;

  const imgProps = { style: { height: '200px', width: 'auto', borderRadius: '4px' } };
  const error = <span>Error while loading image!</span>;
  const placeholder = <span>Loading image...</span>;

  const isCustomMessage = message instanceof CometChat.CustomMessage;
  const isImageMessage = message instanceof CometChat.MediaMessage && message.getType() === CometChat.MESSAGE_TYPE.IMAGE;
  const isPollMessage = message instanceof CometChat.CustomMessage && message.getType() === 'extension_poll';
  const isTextMessage = message instanceof CometChat.TextMessage;
  const isGifMessage = isTextMessage && message.getText().startsWith('https://media.tenor.com/') && message.getText().endsWith('.gif');

  const messageTimeStyle = (!isBlocked && isImageMessage || isGifMessage) ? {
    color: 'rgb(254, 254, 254)',
    padding: '4px',
    opacity: 1,
    borderRadius: '4px 0px',
    backgroundColor: 'rgba(21, 21, 21, 0.45)',
    position: 'absolute',
    bottom: '17px',
    right: '12px',
  } : isPollMessage ? {
    float: 'right',
  } : undefined;

  const getMessageText = (message: CometChat.TextMessage) => message.getText();
  const groupMembersWithoutMe = groupMembers?.filter((member) => member.getUid() !== loggedInUser?.getUid());

  const getHighLight = (message: CometChat.TextMessage) => {
    if (!isTextMessage) return [];
    const text = message.getText();
    const taggedUser = groupMembersWithoutMe?.find((member) => new RegExp(`@${member.getName()}\\b`, 'g').test(text))
      || (loggedInUser && new RegExp(`@${loggedInUser.getName()}\\b`, 'g').test(text) ? loggedInUser : null);

    return taggedUser ? [{ id: taggedUser.getUid(), value: taggedUser.getName(), prefix: '@' }] : [];
  };

  const showUserProfile = (user: CometChat.User) => {
    setCurrentChat((prev: any) => ({ ...prev, userProfile: user }));
  };

  const handleHighlightClick = (event: object, highlight: { id: string, value: string, prefix: string }) => {
    if (highlight.id === loggedInUser?.getUid()) return;
    const user = groupMembers?.find((member) => member.getUid() === highlight.id);
    if (user) showUserProfile(user);
  };

  const renderMessageBody = () => {
    if (isBlocked) return 'Bericht geblokkeerd';
    if (message.getDeletedAt()) return 'Dit bericht is verwijderd';
    
    if (isImageMessage || isGifMessage) {
      return (
        <div onClick={() => setIsDialogOpen(true)}>
          <ImageLoader
            src={isGifMessage ? message.getText() : isImageMessage && message.getURL()}
            imgProps={imgProps}
            error={error}
            placeholder={placeholder}
            onLoad={handleScrollToBottom}
          />
        </div>
      );
    }

    if (isPollMessage) {
      return <PollMessage
        message={message as CometChat.BaseMessage}
        myMessage={myMessage}
        setAlert={setAlert}
        loggedInUser={loggedInUser as CometChat.User}
      />
    }

    if (isCustomMessage) {
      const tags = (message.getCustomData() as any).tags;
      if (!tags) return;
      if (tags.includes('reload_pinned')) return (message.getCustomData() as any).messageInput;
    }

    if (isTextMessage) return getMessageText(message as CometChat.TextMessage);

    return 'Unsupported message type';
  };

  const renderIconMenu = () => (
    <IconMenu icon={<IconChevronDown />}>
      {myMessage ? (
        <>
          <MenuItem
            text="Verwijder Bericht"
            icon={<IconDelete />}
            onClick={() => handleDeleteMessage(message.getId() + '')}
          />
          {(isTextMessage && !isGifMessage) && (
            <MenuItem
              text="Bewerk Bericht"
              icon={<IconEdit />}
              onClick={() => setEditMessage(message as CometChat.TextMessage)}
            />
          )}
        </>
      ) : (
        <MenuItem
          text="Rapporteer Bericht"
          icon={<IconReport />}
          onClick={() => setMessageToReport(message)}
        />
      )}

      {/* {!currentChat.isOpen &&
        <>
          {
            isPinned ? (
              <MenuItem
                text="Bericht losmaken"
                icon={< IconChannel />}
                onClick={() => handleUnpinMessage(message)}
              />
            ) : (
              <MenuItem
                text="Bericht vastzetten"
                icon={<IconChannel />}
                onClick={() => handlePinMessage(message)}
              />
            )}
        </>
      } */}
    </IconMenu>
  );

  return (
    <div id={message.getId() + ''} className={(isImageMessage || isGifMessage) ? 'image-message' : ''}>

      {(isImageMessage || isGifMessage) && (
        <ImageDialog
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
          imageUrl={(isGifMessage ? message.getText() : isImageMessage && message.getURL()) as string}
        />
      )}

      {message.getCategory() === CometChat.CATEGORY_MESSAGE && message.getType() === 'action' ? (
        <MessageSeparator text={isTextMessage ? message.getText() : ''} />
      ) : message.getType() === 'groupMember' && message.getType() === 'groupMember' ? (
        <MessageSeparator text={(message as any).getMessage() ?? ''} />
      ) : isCustomMessage && (message.getCustomData() as any).tags?.includes('reload_pinned') ? (
        <MessageSeparator text={(message.getCustomData() as any).messageInput} />
      ) :
        (
          <Message
            avatar={avatar}
            body={renderMessageBody()}
            highlights={isTextMessage ? getHighLight(message as CometChat.TextMessage) : []}
            onHighlightClick={handleHighlightClick}
            messageTimeStyle={messageTimeStyle}
            type={isBlocked ? 'text' : (isImageMessage || isGifMessage) ? 'img' : 'text'}
            image={isBlocked || !isImageMessage ? '' : message.getURL()}
            createdAt={createdAt}
            username={username}
            myMessage={myMessage}
            separator={separator}
            edited={message.getEditedAt() > 0 && !isPollMessage && 'Bewerkt'}
            iconMenu={renderIconMenu()}
          />
        )}
    </div>
  );
};

export default MessageComponent;