import { useState, useEffect } from 'react';
import ChatPlazaLogoSvg from '../../assets/chatplaza-logo.svg';
import './styles.css';

const LoadingPage = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prevDots => (prevDots.length < 3 ? prevDots + '.' : ''));
    }, 500); // Update every 500ms

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="loading-page">
      <img src={ChatPlazaLogoSvg} alt="ChatPlaza Logo" />
      <h1>Laden{dots}</h1>
    </div>
  );
}

export default LoadingPage;